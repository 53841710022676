/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable react/display-name */
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

export default ({
  isOpen,
  onClose,
  title,
  children,
  width,
  isFooter,
  ...rest
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      scrollBehavior='inside'
      {...rest}
    >
      <ModalOverlay />
      <ModalContent
        borderRadius={'0px'}
        border={'1px #7A7A7A'}
        minW={width}
        p={'30px'}
      >
        <ModalHeader p={'0px'}>
          <Text fontSize={'24px'} fontWeight={600} lineHeight={'150%'}>
            {title}
          </Text>
        </ModalHeader>
        <ModalCloseButton 
         mt="-25px" 
         mr="-25px" 
         bg="black" 
         color="white" 
         borderRadius="15px" 
         _hover={{ bg: "gray.700" }}/>
        
        <ModalBody
          p={'10px 0px'}
          display={'flex'}
          flexDir={'column'}
          gap={'20px'}
        >
          {children}
        </ModalBody>
        {isFooter && (
          <ModalFooter gap={'10px'} justifyContent={'right'}>
            <Button variant={'secondary'} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
