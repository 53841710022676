import React, { useEffect } from 'react';
import Homepage from '../pages/homepage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Listing from '../pages/product/listing';
import PublicRoutes from './public-outlet';
import PrivateRoutes from './private-outlet';
import Profile from '../pages/profile';
import ProductDetail from '../pages/product-detail';
import Forgotpassword from '../pages/forgotpassword/index';
import Shippingaddress from '../pages/checkout/shipping-address';
import Personaldetail from '../pages/checkout/personal-detail';
import Payment from '../pages/checkout/payment';
import PaymentStatus from '../pages/checkout/payment-status';
import OrderDetail from '../pages/order-detail';
import TermConditions from '../pages/terms-conditions';
import About from '../pages/about-us';
import Contact from '../pages/contact-us';
import Reviews from '../pages/reviews';
import Notifications from '../pages/notifications';
import FAQS from '../pages/qna';
import Privacy from '../pages/privacy-policy';
import CookieNotice from '../pages/cookie-notice';
import RefundPolicy from '../pages/refund-policy';
import ShippingReturnCancellationPolicy from '../pages/shipping-return-cancellation';
import VIP from '../pages/vip';
import SizeGuide from '../pages/size-guide';
import OrderTrackingGuide from '../pages/how-track-order';
import OrderGuide from '../pages/how-to-order';
import ReturnPolicy from '../pages/return-policy';
import AccountVerification from '../pages/account-verification';
import PageNotFound from '../pages/page-not-found';

import { useDispatch, useSelector } from 'react-redux';
import Cart from '../pages/product/cart';
import Wishlist from '../pages/wishlist';
import SubCategory from '../pages/sub-category';
import OrderSummary from '../pages/checkout/order-summary';
import CreateOrder from '../pages/create-order';
import { v4 as uuidv4 } from 'uuid';
import { setGuestInfo } from '../redux/guestSlice';
import { fetchCartDetails } from '../redux/cartSlice';
import { fetchWishlistDetails } from '../redux/wishlistSlice';
import {
  fetchCountryList,
  setCountry,
  setCurrency,
  setSelectedCountry,
} from '../redux/homeSlice';
import { currencyList } from '../utils/constants';
import MyOrders from '../pages/myOrders';
import Orderlist from '../pages/order-list';
import ActionButtons from '../components/action-buttons';

const publicRouteLinks = [
  {
    path: '/',
    element: <Homepage />,
  },
  {
    path: '/product/listing',
    element: <Listing />,
  },
  {
    path: '/product/details/:id',
    element: <ProductDetail />,
  },
  {
    path: '/product/cart',
    element: <Cart />,
  },
  {
    path: '/product/category/:id/:name',
    element: <SubCategory />,
  },
  {
    path: '/wishlist',
    element: <Wishlist />,
  },
  {
    path: '/order-detail',
    element: <OrderDetail />,
  },
  {
    path: '/create/order',
    element: <CreateOrder />,
  },
  {
    path: '/forgot-password',
    element: <Forgotpassword />,
  },
  {
    path: '/terms-and-conditions',
    element: <TermConditions />,
  },
  {
    path: '/about-us',
    element: <About />,
  },
  {
    path: '/contact-us',
    element: <Contact />,
  },
  {
    path: '/reviews',
    element: <Reviews />,
  },
  {
    path: '/notifications',
    element: <Notifications />,
  },
  {
    path: '/qna',
    element: <FAQS />,
  },
  {
    path: '/privacy-policy',
    element: <Privacy />,
  },
  {
    path: '/cookie-notice',
    element: <CookieNotice />,
  },
  {
    path: '/refund-policy',
    element: <RefundPolicy />,
  },
  {
    path: '/shipping-return-cancellation-policy',
    element: <ShippingReturnCancellationPolicy />,
  },
  {
    path: '/vip',
    element: <VIP />,
  },
  {
    path: '/size-guide',
    element: <SizeGuide />,
  },
  {
    path: '/how-to-track-order',
    element: <OrderTrackingGuide />,
  },
  {
    path: '/how-to-order',
    element: <OrderGuide />,
  },
  {
    path: '/return-policy',
    element: <ReturnPolicy />,
  },
  {
    path: '/verify-account',
    element: <AccountVerification />,
  },
  {
    path: '/my-orders',
    element: <Orderlist />,
    isAuth: true,
  },
  {
    path: '/order-detail/:id',
    element: <OrderDetail />,
    isAuth: true,
  },
  {
    path: '/profile',
    element: <Profile />,
    isAuth: true,
  },
];

const RouteComponent = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);
  let auth = { token: localStorage.getItem('token') };

  useEffect(() => {
    dispatch(fetchCartDetails());
    dispatch(fetchWishlistDetails());
    dispatch(fetchCountryList());
  }, [dispatch]);

  useEffect(() => {
    const guestInfo = JSON.parse(localStorage.getItem('guestInfo'));

    if (!guestInfo?.id) {
      localStorage.setItem('guestInfo', JSON.stringify({ id: uuidv4() }));
    }
    dispatch(setGuestInfo(guestInfo || uuidv4()));
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        {userData || auth.token ? (
          <Route element={<PrivateRoutes />}>
            {publicRouteLinks.map((route) => (
              <Route
                exact
                path={route.path}
                element={route.element}
                key={route.path}
              />
            ))}
            <Route path='*' element={<PageNotFound />} />
          </Route>
        ) : (
          <Route element={<PublicRoutes />}>
            {publicRouteLinks.map((route) => (
              <Route
                exact
                path={route.path}
                element={route.element}
                key={route.path}
              />
            ))}
            <Route path='*' element={<PageNotFound />} />
          </Route>
        )}
      </Routes>
    </Router>
  );
};

export default RouteComponent;
