import { Flex } from '@chakra-ui/react';
import React, { useState } from 'react';
import { BsChevronUp } from 'react-icons/bs';
import { PiChatCircleDotsLight } from 'react-icons/pi';
import { RiEdit2Line } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';

const ActionButtons = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 1200) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      /* you can also use 'auto' behaviour 
             in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);
  return (
    <Flex
      flexDir={'column'}
      gap={'10px'}
      pos={'fixed'}
      right={'10px'}
      bottom={'10px'}
    >
      <Flex
        border={'2px solid #ddd'}
        height={'40px'}
        width={'40px'}
        borderRadius={'50%'}
        fontSize={'20px'}
        align={'center'}
        justify={'center'}
        cursor={'pointer'}
        onClick={() => navigate('/qna')}
      >
        <PiChatCircleDotsLight />
      </Flex>
      <Flex
        border={'2px solid #ddd'}
        height={'40px'}
        width={'40px'}
        borderRadius={'50%'}
        fontSize={'20px'}
        align={'center'}
        justify={'center'}
        cursor={'pointer'}
      >
        <RiEdit2Line />
      </Flex>
      {visible && (
        <Flex
          border={'2px solid #ddd'}
          height={'40px'}
          width={'40px'}
          borderRadius={'50%'}
          fontSize={'20px'}
          align={'center'}
          justify={'center'}
          cursor={'pointer'}
          onClick={scrollToTop}
        >
          <BsChevronUp />
        </Flex>
      )}
    </Flex>
  );
};

export default ActionButtons;
