import { ChakraProvider, Container, Spinner } from '@chakra-ui/react';
import RouteComponent from './routes';
import { Provider } from 'react-redux';
import store from './redux/store';
import theme from './theme';
import { Suspense } from 'react';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <Suspense fallback={<Spinner />}>
          <RouteComponent />
        </Suspense>
      </Provider>
    </ChakraProvider>
  );
}

export default App;
