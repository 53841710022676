import { createSlice } from '@reduxjs/toolkit';
import {
  addToCart,
  deleteCart,
  getCartList,
  updateCart,
  updateCartQuantity,
} from '../services/cart-service';

const initialState = {
  isLoading: false,
  cartList: [],
  cartDetails: null,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setCartList(state, action) {
      state.cartList = action.payload;
    },
    setCartDetails(state, action) {
      state.cartDetails = action.payload;
    },
  },
});

export const { setIsLoading, setProductDetails, setCartList, setCartDetails } =
  cartSlice.actions;

export default cartSlice.reducer;

export const addToCartHandler =
  (data, isWishlist = 0) =>
  async (dispatch, getState) => {
    try {
      const userData = getState().user.userData;
      const guestInfo = getState().guest.guestInfo;
      const country = getState().home.country;
      const currency = getState().home.currency;
      const payload = {
        currency,
        country,
        product_id: isWishlist === 1 ? data.product_id : data?.id,
        user_id: userData?.id || guestInfo.id,
        quantity: data.quantity || 1,
        amount: data.price,
        varient_value: data?.default_sku_attribute,
        sku_id: data.sku_id,
        is_wishlist: isWishlist,
        ...(isWishlist === 0 && {
          logistic_service: data.logistic_service,
          shipping: data.shipping,
        }),
      };
      return await addToCart(payload);
    } catch (error) {
      return error;
    }
  };
export const updateCartQuantityHandler =
  (payload, quantity) => async (dispatch, getState) => {
    try {
      const userData = getState().user.userData;
      const guestInfo = getState().guest.guestInfo;
      const country = getState().home.country;
      const currency = getState().home.currency;
      const _payload = {
        id: payload.id,
        quantity,
        user_id: userData?.id || guestInfo.id,
        amount: parseFloat(payload.price.replace(',', '')),
        currency,
        country,
      };
      const response = await updateCartQuantity(_payload);
      if (response.status === 200) {
        dispatch(fetchCartList(userData?.id || guestInfo.id));
      }
    } catch (error) {
      return error;
    }
  };
export const updateCartHandler = (payload) => async (dispatch, getState) => {
  try {
    const userData = getState().user.userData;
    const guestInfo = getState().guest.guestInfo;
    const country = getState().home.country;
    const currency = getState().home.currency;
    const _payload = {
      id: payload.id,
      user_id: userData?.id || guestInfo.id,
      shipping: parseFloat(payload.shipment_fee.replace(',', '')),
      currency,
      country,
    };
    const response = await updateCart(_payload);
    if (response.status === 200) {
      dispatch(fetchCartList(userData?.id || guestInfo.id));
    }
  } catch (error) {
    return error;
  }
};
export const fetchCartList = (userId) => async (dispatch, getState) => {
  try {
    const country = getState().home.country;
    const currency = getState().home.currency;
    const response = await getCartList(userId, country, currency);
    if (response.status === 200) {
      localStorage.setItem('cartDetails', JSON.stringify(response.data));
      dispatch(setCartList(response.data.data));
      dispatch(setCartDetails(response.data));
    }
  } catch (error) {
    return error;
  }
};
export const fetchCartDetails = (userId) => async (dispatch) => {
  try {
    const cartDetails = JSON.parse(localStorage.getItem('cartDetails'));
    dispatch(setCartDetails(cartDetails));
  } catch (error) {
    return error;
  }
};

export const deleteCartHandler = (id) => async (dispatch, getState) => {
  try {
    const userData = getState().user.userData;
    const guestInfo = getState().guest.guestInfo;
    const userId = userData?.id || guestInfo.id;
    const paylaod = { id, user_id: userId };
    const response = await deleteCart(paylaod);
    if (response.status === 200) {
      dispatch(fetchCartList(userData?.id || guestInfo.id));
    }
  } catch (error) {
    return error;
  }
};
