import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';
import { useLayoutEffect } from 'react';
import { Container } from '@chakra-ui/react';
import ActionButtons from '../components/action-buttons';
const PublicRoutes = () => {
  let auth = { token: localStorage.getItem('token') };
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };
  return !auth.token ? (
    <Wrapper>
      <Header />
      <Container maxW='100%' padding={'0 120px'}>
        <Outlet />
      </Container>

      <Footer />
      <ActionButtons />
    </Wrapper>
  ) : (
    <Navigate to='/' />
  );
};

export default PublicRoutes;
