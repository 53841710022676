import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import ProductSlider from '../../slider';
import { useSelector } from 'react-redux';
import styles from './deals.module.scss';
import ArrowRight from '../../icons/arrow-right';
import { useNavigate } from 'react-router-dom';

const BestDeals = ({ title, onCartOpen }) => {
  const navigate = useNavigate();
  const {
    homeData: { best_deal_products, best_deal_attributes },
  } = useSelector((state) => state.home);

  return (
    <Box
      className={styles.best_deals}
      bg='#FFF'
      // mt='10px'
      // padding={'20px 29px'}
    >
      <Flex justify={'space-between'} align={'center'} mb='30px'>
        <Text color={'#000'} fontSize={'16px'} fontWeight={500}>
          {title}
        </Text>
        <Flex align={'center'} gap={'5px'} cursor={'pointer'}>
          <Text
            lineHeight={'18px'}
            fontWeight={700}
            fontSize={'16px'}
            color={'#222'}
            onClick={() => {
              navigate(
                `product/listing?page_no=1&min_discount=${best_deal_attributes?.min_discount}&max_discount=${best_deal_attributes?.max_discount}&is_free_shipping=1&is_sold=${best_deal_attributes.is_sold}&rating=${best_deal_attributes.rating}`
              );
            }}
          >
            View All
          </Text>
          <ArrowRight />
        </Flex>
      </Flex>

      <Divider mb='35px' mt='-10px' />
      <ProductSlider
        items={best_deal_products}
        isBestDeals
        onCartOpen={onCartOpen}
      />
    </Box>
  );
};

export default BestDeals;
