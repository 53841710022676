import React from 'react';

const WhatsappIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        d='M16.7228 14.0602C16.4673 13.9289 15.1946 13.3055 14.9579 13.2211C14.7212 13.132 14.5478 13.0898 14.3767 13.3523C14.2032 13.6125 13.711 14.1914 13.5563 14.3672C13.4063 14.5406 13.254 14.5617 12.9985 14.4328C11.4798 13.6734 10.4837 13.0781 9.48291 11.3602C9.21807 10.9031 9.74775 10.9359 10.2423 9.94922C10.3267 9.77578 10.2845 9.62813 10.2188 9.49688C10.1532 9.36563 9.6376 8.09531 9.42197 7.57734C9.21338 7.07344 8.99775 7.14375 8.84072 7.13437C8.69072 7.125 8.51963 7.125 8.34619 7.125C8.17275 7.125 7.89385 7.19063 7.65713 7.44609C7.42041 7.70625 6.75244 8.33203 6.75244 9.60234C6.75244 10.8727 7.67822 12.1031 7.80478 12.2766C7.93603 12.45 9.62588 15.0563 12.2204 16.1789C13.861 16.8867 14.5032 16.9477 15.3235 16.8258C15.8228 16.7508 16.8517 16.2023 17.0649 15.5953C17.2782 14.9906 17.2782 14.4727 17.2149 14.3648C17.1517 14.25 16.9782 14.1844 16.7228 14.0602Z'
        fill='#303030'
      />
      <path
        d='M21.6843 7.93125C21.1546 6.67266 20.3952 5.54297 19.4272 4.57266C18.4593 3.60469 17.3296 2.84297 16.0686 2.31563C14.7796 1.77422 13.4108 1.5 11.9999 1.5H11.953C10.5327 1.50703 9.15692 1.78828 7.86317 2.34141C6.61395 2.87578 5.49364 3.63516 4.53505 4.60312C3.57645 5.57109 2.82411 6.69609 2.3038 7.95C1.76473 9.24844 1.49286 10.6289 1.49989 12.0492C1.50692 13.6758 1.89598 15.2906 2.62489 16.7344V20.2969C2.62489 20.8922 3.1077 21.375 3.70301 21.375H7.26786C8.71161 22.1039 10.3265 22.493 11.953 22.5H12.0022C13.4061 22.5 14.7679 22.2281 16.0499 21.6961C17.3038 21.1734 18.4311 20.4234 19.3968 19.4648C20.3647 18.5063 21.1265 17.3859 21.6585 16.1367C22.2116 14.843 22.4929 13.4672 22.4999 12.0469C22.5069 10.6195 22.2304 9.23438 21.6843 7.93125ZM18.1429 18.1969C16.4999 19.8234 14.3202 20.7188 11.9999 20.7188H11.96C10.5468 20.7117 9.14286 20.3602 7.90301 19.6992L7.70614 19.5938H4.40614V16.2938L4.30067 16.0969C3.63973 14.857 3.28817 13.4531 3.28114 12.0398C3.27176 9.70312 4.16473 7.50937 5.80301 5.85703C7.43895 4.20469 9.62567 3.29062 11.9624 3.28125H12.0022C13.1741 3.28125 14.3108 3.50859 15.3819 3.95859C16.4272 4.39687 17.3647 5.02734 18.171 5.83359C18.9749 6.6375 19.6077 7.57734 20.046 8.62266C20.5007 9.70547 20.728 10.8539 20.7233 12.0398C20.7093 14.3742 19.7929 16.5609 18.1429 18.1969Z'
        fill='#303030'
      />
    </svg>
  );
};

export default WhatsappIcon;
