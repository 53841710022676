import React, { useEffect, useState } from "react";
import { Box, Flex, Image, Text, Icon, Button, Input } from "@chakra-ui/react";
import { RiArrowRightDoubleFill } from "react-icons/ri";
import LeftImage from "../../assets/img/about-bg.svg";
import BgImage from "../../assets/img/about.jpg";
import { FaGem, FaTools, FaUsers } from "react-icons/fa";

const Index = () => {
  const [projectsCount, setProjectsCount] = useState(1);
  const [customersCount, setCustomersCount] = useState(1);
  const [clientsCount, setClientsCount] = useState(1);

  useEffect(() => {
    const animateCounts = () => {
      if (projectsCount < 137) {
        setTimeout(() => {
          setProjectsCount(projectsCount + 1);
        }, 20);
      }
      if (customersCount < 2500) {
        setTimeout(() => {
          setCustomersCount(customersCount + 20); // Adjust the increment for faster animation
        }, 10);
      }
      if (clientsCount < 22) {
        setTimeout(() => {
          setClientsCount(clientsCount + 1);
        }, 50);
      }
    };

    animateCounts();

    return () => {
      clearTimeout();
    };
  }, [projectsCount, customersCount, clientsCount]);

  return (
    <Box>
      {/* <Flex
        flexDir={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justifyContent={{ base: "flex-start", md: "space-between" }}
        p="25px 20px 0px 20px"
      >
        <Text fontSize="20px" fontWeight="bold">
          About Us
        </Text>
        <Flex alignItems="center" gap="10px">
          <Text color="#E6533C" fontSize="14px">
            Pages
          </Text>
          <RiArrowRightDoubleFill color="#E6533C" />
          <Text fontWeight="bold" fontSize="14px">
            About Us
          </Text>
        </Flex>
      </Flex> */}

      <Box
        p="20px"
        m="20px"
        color="white"
        border="1px solid gray"
        borderRadius="10px"
      >
        <Box
          padding="15px 40px"
          position="relative"
          borderRadius="10px"
          border="1px solid transparent"
          height={{base:"1100px", md:"500px"}}
          display="flex"
          flexDir="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            _before={{
              content: `""`,
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundImage: `url(${BgImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              // opacity: 0.8,
              zIndex: -1,
              borderRadius: "10px",
            }}
          />
          <Flex
            align="center"
            justify="space-between"
            direction={{ base: "column", md: "row" }}
          >
            <Box
              maxW={{ base: "100%", md: "600px" }}
              textAlign={{ base: "left", md: "left" }}
              marginTop={{base:"-400px", md:"0px"}}
            >
              <Box>
                <Text fontWeight="bold">About us </Text>
                <Box
                  w="10%"
                  h="5px"
                  bgGradient="linear(to-r, #ffffff, rgba(155, 89, 182, 0))"
                  borderRadius="5px"
                  mb="10px"
                  mt="10px"
                />
              </Box>
              <Text fontSize="3xl" fontWeight="bold">
                DISCIPLINE IS WHAT{" "}
                <Text as="span" color="teal.300">
                  MOTIVATES
                </Text>{" "}
                YOU MOST
              </Text>
              <Text mt={4}>
                You fight like a younger man, there’s nothing held back. It’s
                admirable, but mistaken. When their enemies were at the gates
                the Romans would suspend democracy and appoint one man to
                protect the city...
              </Text>
              <Button mt={6} colorScheme="teal">
                Get Started
              </Button>
            </Box>
            <Box display={{ base: "none", md: "block" }}>
              <Image src={LeftImage} alt="About Image" width="70%" />
            </Box>
          </Flex>

          <Flex
            flexDir={{ base: "column", md: "row" }}
            position="absolute"
            bottom={{base:"7%", md:"-45px"}}
            bg="white"
            color="black"
            left="50%"
            transform="translateX(-50%)"
            boxShadow="0px 1px 3px rgba(0, 0, 0, 0.1)"
            borderRadius="8px"
          >
            <Flex
              flexDir="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              borderRight="1px dashed gray"
              height="100px"
              width="200px"
            >
              <Text fontSize="sm">Lorem</Text>
              <Text fontSize="3xl">{projectsCount}+</Text>
            </Flex>
            <Flex
              flexDir="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              borderRight="1px dashed gray"
              height="100px"
              width="200px"
            >
              <Text fontSize="sm">Lorem</Text>
              <Text fontSize="3xl">{customersCount}+</Text>
            </Flex>
            <Flex
              flexDir="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="100px"
              width="200px"
            >
              <Text fontSize="sm">Lorem</Text>
              <Text fontSize="3xl">{clientsCount}+</Text>
            </Flex>
          </Flex>
        </Box>

        <Box p={{ base: 4, md: 8 }} maxW="3xl" mx="auto" mt="80px">
          <Text fontSize="24px" color="black" fontWeight="500" mb={2}>
            Lorem ipsum
          </Text>
          <Box
            w="10%"
            h="5px"
            bgGradient="linear(to-r,  #f41106, rgba(155, 89, 182, 0))"
            borderRadius="5px"
            mb="10px"
            mt="10px"
          />
          <Text mb={8} color="#707071" fontSize="14px">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam
            similique provident Lorem ipsum dolor sit amet consectetur
            adipisicing elit. Natus, aliquam voluptas repellat eum beatae
            veniam, cumque eligendi earum praesentium, in corrupti reprehenderit
            cum architecto quisquam? Quibusdam quaerat veritatis perferendis
            iusto. !
          </Text>
          <Flex flexDir="column" gap="30px">
            <Flex gap="20px">
              <Icon as={FaGem} w={8} h={8} color="teal.500" />
              <Box>
                <Text fontSize="18px" color="black" fontWeight="500">
                  Lorem
                </Text>
                <Text color="#707071" fontSize="14px">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Adipisci quos sint, officia vel ab perferendis, dolores
                  placeat dolor aliquam debitis eius, illum ullam ratione
                  blanditiis fugiat omnis beatae odio vitae!
                </Text>
              </Box>
            </Flex>
            <Flex gap="20px">
              <Icon as={FaTools} w={8} h={8} color="teal.500" />
              <Box>
                <Text fontSize="18px" color="black" fontWeight="500">
                 Lorem
                </Text>
                <Text color="#707071" fontSize="14px">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Adipisci quos sint, officia vel ab perferendis illum ullam
                  ratione blanditiis fugiat omnis beatae odio vitae!
                </Text>
              </Box>
            </Flex>
            <Flex gap="20px">
              <Icon as={FaUsers} w={8} h={8} color="teal.500" />
              <Box>
                <Text fontSize="18px" color="black" fontWeight="500">
                  Lorem 
                </Text>
                <Text color="#707071" fontSize="14px">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Adipisci quos sint, officia vel ab perferendis, dolores
                  placeat dolor aliquam debitis eius, illum ullam ratione
                  blanditiis fugiat omnis beatae odio vitae!
                </Text>
              </Box>
            </Flex>
          </Flex>
        </Box>
        <Box
          width="100%"
          bg="purple.500"
          color="white"
          py={10}
          px={{ base: 4, md: 8 }}
          textAlign="center"
          borderRadius="10px"
        >
          <Text fontSize="lg" fontWeight="bold">
            For any queries
          </Text>
          <Box
            w="10%"
            h="5px"
            bgGradient="linear(to-r,  #ffffff, rgba(155, 89, 182, 0))"
            borderRadius="5px"
            mb="10px"
            mt="10px"
            align="center"
            mx="auto"
          />
          <Text mb={6}>Feel free to contact us any time</Text>
          <Flex
            maxW="600px"
            mx="auto"
            direction={{ base: "column", md: "row" }}
            align="center"
            justify="center"
          >
            <Flex
              as="form"
              direction={{ base: "row", md: "row" }}
              bg="white"
              borderRadius="md"
              overflow="hidden"
              width="100%"
            >
              <Input
                placeholder="Ask Here"
                bg="white"
                color="gray.700"
                borderRadius="0"
                border="none"
                mr="0px"
                width="100%"
                // borderColor="1px solid transparent"
                _focus={{
                  outline: "none",
                  boxShadow: "none",
                  borderColor: "transparent",
                }}
                _hover={{ borderColor: "none" }}
              />
              <Button
                colorScheme="teal"
                borderRadius="0"
                height="100%"
              >
                Send
              </Button>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default Index;
