import { Button, Flex, SimpleGrid, useToast } from '@chakra-ui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  addAddressHandler,
  fetchAddressHandler,
  updateAddressHandler,
} from '../../redux/userSlice';
import FormInput from '../../components/form-input';
import FormCheckbox from '../../components/form-checkbox';

const AddressForm = ({ data, update, onClose }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: data?.full_name || userData?.name || '',
        phone: userData?.phone || '',
        email: userData?.email || '',
        pincode: data?.pin_code || '',
        city: data?.city || '',
        state: data?.state || '',
        country: data?.country || '',
        is_default: data?.is_default === 1 ? true : false,
        address: data?.address || '',
      }}
      validationSchema={Yup.object({
        name: Yup.string().required('Required'),
        pincode: Yup.string().required('Required'),
        city: Yup.string().required('Required'),
        state: Yup.string().required('Required'),
        country: Yup.string().required('Required'),
        address: Yup.string().required('Required'),
        phone:
          Yup.string()
          .required('Required'),
        email: Yup.string().email('Invalid email address').required('Required'),
      })}
      onSubmit={(values) => {
        const payload = {
          user_id: userData?.id,
          is_default: values.is_default ? '1' : '0',
          full_name: values.name,
          email: values.email,
          phone: values.phone,
          address: values.address,
          pincode: values.pincode,
          city: values.city,
          state: values.state,
          country: values.country,
        };
        if (update) {
          payload.address_id = data.id;
          dispatch(updateAddressHandler(payload)).then((res) => {
            if (res.status === 200) {
              toast({
                title: 'Address updated Successfully',
                status: 'success',
                duration: 9000,
                isClosable: true,
              });
              dispatch(fetchAddressHandler());
              onClose();
            } else {
              toast({
                title: res?.response?.data?.message ?? 'Something went wrong',
                status: 'error',
                duration: 9000,
                isClosable: true,
              });
            }
          });
        } else {
          dispatch(addAddressHandler(payload)).then((res) => {
            if (res.status === 200) {
              toast({
                title: 'Address added Successfully',
                status: 'success',
                duration: 9000,
                isClosable: true,
              });
            } else {
              toast({
                title: res?.response?.data?.message ?? 'Something went wrong',
                status: 'error',
                duration: 9000,
                isClosable: true,
              });
            }
          });
        }
      }}
      validateOnMount
    >
      {({ isValid, values }) => (
        <Flex flexDir={'column'} as={Form} gap={'1.25em'} mt='1.26rem'>
          <Flex flexDir={'column'} gap={'20px'}>
            <SimpleGrid columns={2} gap={6}>
              <FormInput
                name='name'
                type='text'
                label='Full Name'
                placeholder='Full Name'
              />
              <FormInput
                name='email'
                type='text'
                label='Email Address'
                placeholder='Email Address'
              />
            </SimpleGrid>

            <FormInput
              name='phone'
              type='number'
              label='Phone'
              placeholder='Phone'
            />
            <SimpleGrid columns={4} gap={6}>
              <FormInput
                name='pincode'
                type='text'
                label='Pincode'
                placeholder='Pincode'
              />
              <FormInput
                name='city'
                type='text'
                label='City'
                placeholder='City'
              />
              <FormInput
                name='state'
                type='text'
                label='State'
                placeholder='State'
              />
              <FormInput
                name='country'
                type='text'
                label='Country'
                placeholder='Country'
              />
            </SimpleGrid>
            <SimpleGrid columns={2} gap={6}>
              <FormInput
                name='address'
                as='Textarea'
                label='Address'
                placeholder='Address'
              />
              <FormCheckbox
                type='checkbox'
                name='is_default'
                defaultChecked={values.is_default}
                label={'Set this address as default address'}
                mt='1.68rem'
              />
            </SimpleGrid>
          </Flex>

          <Button
            variant={'primary'}
            isDisabled={!isValid}
            size={'sm'}
            type='submit'
            w={'200px'}
            // isLoading={isLoading}
            loadingText='Please wait...'
          >
            {update ? 'Update Address' : 'Add Address'}
          </Button>
        </Flex>
      )}
    </Formik>
  );
};

export default AddressForm;
