import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { accountVerification } from '../../services/auth-service';
import { Image, Box, Text, Flex, Spinner, Button } from '@chakra-ui/react';
import Verified from "../../../src/assets/img/verified.png";
import Failed from "../../../src/assets/img/failed.png";
import PageNotFound from '../page-not-found';

const AccountVerification = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token'); 
    const [isVerified, setIsVerified] = useState(null); 

    useEffect(() => {
        const verifyAccount = async () => {
            try {
                const response = await accountVerification(token);
                setIsVerified(response.data.success);
            } catch (error) {
                console.error("Verification failed:", error);
                setIsVerified(false);
            }
        };
        verifyAccount();
    }, [token]);

    return (
        <Box textAlign="center" mt="50px">
            {isVerified === null && (
                <Flex justifyContent="center" alignItems="center" height="100vh">
                    <Spinner size="xl" />
                </Flex>
            )}
            {isVerified === true && (
                <Flex width="100%" justifyContent="center" alignItems="center" flexDir="column" padding="50px">
                    <Image src={Verified} alt="Verified" mx="auto" width="10%"/>
                    <Text fontSize="xl" mt="20px">User Verified Successfully! Please Login</Text>
                </Flex>
            )}
            {isVerified === false && (
                <PageNotFound/>
            )}
        </Box>
    );
};

export default AccountVerification;
