import {
  Badge,
  Box,
  Button,
  Container,
  Flex,
  IconButton,
  Image,
  Text,
  Tooltip,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Stack,
  Icon,
  Input,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import NumberGroupInput from '../../../components/number-group-input';
import { RiBox3Line, RiDeleteBin2Line, RiHeartLine } from 'react-icons/ri';
import styles from './cart.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteCartHandler,
  fetchCartList,
  updateCartQuantityHandler,
} from '../../../redux/cartSlice';
import { useNavigate } from 'react-router-dom';
import {
  calculateDiscountPercentage,
  formatePrice,
} from '../../../utils/functions';
import { PiWarningCircleThin } from 'react-icons/pi';
import { AiOutlineSafety } from 'react-icons/ai';
import { GiThreeLeaves } from 'react-icons/gi';
import { addToWishlistHandler } from '../../../redux/wishlistSlice';
import { GoPackage } from 'react-icons/go';
import ShippingModal from '../../product-detail/logistics/ship-option';
import ProductDetail from '../../product-detail';
import DeleteCartModal from './delete-cart-modal';
import Modal from '../../../components/modal';
import { fetchShipmentList, setProductId } from '../../../redux/productSlice';

const Cart = () => {
  const {
    isOpen: isShippingOpen,
    onOpen: onShippingOpen,
    onClose: onShippingClose,
  } = useDisclosure();
  const {
    isOpen: isOpenCart,
    onOpen: onOpenCart,
    onClose: onCloseCart,
  } = useDisclosure();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const { userData } = useSelector((state) => state.user);
  const { guestInfo } = useSelector((state) => state.guest);
  const { cartDetails } = useSelector((state) => state.cart);
  const [cartItems, setCartItems] = useState([]);
  const [coupon, setCoupon] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const { currency, country } = useSelector((state) => state.home);
  const [selectedItem, setSelectedItem] = useState(null);
  const [productData, setProductData] = useState(null);
  const [qty, setQty] = useState(null);

  useEffect(() => {
    if (cartDetails?.data?.length === 0)
      dispatch(fetchCartList(userData?.id || guestInfo.id));
  }, [cartDetails?.data?.length, dispatch, guestInfo.id, userData?.id]);

  useEffect(() => {
    if (cartDetails?.data) {
      setCartItems(cartDetails.data);
    }
  }, [cartDetails]);

  const onClose = () => setIsOpen(false);

  const handleDeleteClick = (item) => {
    setSelectedItem(item);
    setIsOpen(true);
  };

  const confirmDelete = () => {
    if (selectedItem) {
      dispatch(deleteCartHandler(selectedItem.id));
      setCartItems(cartItems.filter((item) => item.id !== selectedItem.id));
      onClose();
    }
  };

  const handleQuantityChange = (item, quantity) => {
    setQty(quantity);
    const updatedCartItems = cartItems.map((cartItem) =>
      cartItem.id === item.id ? { ...cartItem, quantity } : cartItem
    );
    console.log(updatedCartItems, 'updatedCartItems');

    setCartItems(updatedCartItems);
    dispatch(updateCartQuantityHandler(item, quantity));
  };

  const handleAddToWishlistItem = (item) => {
    dispatch(addToWishlistHandler(item));
    toast({
      title: 'Product added to wishlist',
      status: 'success',
      duration: 2000,
      isClosable: true,
      onCloseComplete: () => navigate('/wishlist'),
    });
  };

  const onShipping = (item) => {
    console.log(item, 'item');
    setSelectedItem(item);
    onShippingOpen();
  };

  const cartHandler = (data) => {
    try {
      dispatch(setProductId(data.product_unique_id));
      setProductData(data);
      onOpenCart();
      console.log('cart opened ');
    } catch (error) {
      console.log(error.message, 'error');
    }
  };

  console.log(isOpenCart, 'isOpenCart');

  const calculateTotal = (price, quantity) => price * quantity;

  useEffect(() => {
    if (selectedItem?.id) {
      const payload = {
        id: selectedItem?.id,
        country: country,
        currency: currency,
        sku_id: selectedItem.sku_id,
        quantity: selectedItem.quantity || 1,
      };
      dispatch(fetchShipmentList(payload));
    }
  }, [country, selectedItem, currency]);

  return (
    <div style={{ marginBottom: '20px' }}>
      {cartItems?.length === 0 && (
        <Flex
          flexDir={'column'}
          gap={'10px'}
          justify={'center'}
          align={'center'}
          p='50px'
          minH='500px'
        >
          <Text fontSize={'24px'}>Your cart is empty!</Text>
          <Text>Add items to it now.</Text>
          <Button variant='primary' onClick={() => navigate('/')}>
            Shop Now
          </Button>
        </Flex>
      )}

      {cartItems?.length > 0 && (
        <>
          <Flex py='20px' justify={'space-between'} align={'center'}>
            <Text fontSize={'18px'} fontWeight={600}>
              Cart {console.log(cartItems, 'cart items')}
            </Text>
          </Flex>

          <Flex
            direction={{ base: 'column', lg: 'row' }}
            gap='20px'
            wrap={{ base: 'wrap', lg: 'nowrap' }}
            alignItems='flex-start'
          >
            {/* Main Content */}
            <Container
              minW='78%'
              maxW='100%'
              p='1px'
              bg='#666666'
              borderRadius='0.625rem'
              flexGrow={1}
            >
              <div
                style={{
                  backgroundColor: 'white',
                  padding: '1rem',
                  borderRadius: '0.625rem 0.625rem 0 0',
                }}
              >
                <Text fontSize='.93rem' fontWeight={700}>
                  Cart Item
                </Text>
              </div>
              <hr />
              <Box
                className={styles.box}
                overflowX='auto'
                borderRadius='0 0 0.625rem 0.625rem'
              >
                <Table className={styles.simple_table} bg='white'>
                  <Thead>
                    <Tr>
                      <Th color='black' w='45%'>
                        Product Name
                      </Th>
                      <Th color='black' w='15%'>
                        Price
                      </Th>
                      <Th color='black' w='15%'>
                        Quantity
                      </Th>
                      <Th color='black' w='15%'>
                        Total
                      </Th>
                      <Th color='black' w='10%'>
                        Actions
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {cartItems.map((item, index) => (
                      <Tr key={index}>
                        <Td w='45%'>
                          <Flex
                            gap={'1rem'}
                            cursor={'pointer'}
                            onClick={() => cartHandler(item)}
                          >
                            <Flex
                              alignItems='center'
                              justifyContent='center'
                              bg='#EDEDED'
                              p={'5px'}
                              borderRadius={'8px'}
                              height={'90px'}
                              width={'90px'}
                            >
                              <Image
                                width={'100%'}
                                src={
                                  item.image ||
                                  require('../../../assets/img/product/no-image.png')
                                }
                              />
                            </Flex>
                            <Flex flexDir={'column'}>
                              <Tooltip label={item.name}>
                                <Text
                                  fontWeight={600}
                                  fontSize={14}
                                  className={styles.product_name}
                                >
                                  {item.name}
                                </Text>
                              </Tooltip>
                              {item.attributes?.map((attr, idx) => (
                                <Flex gap={'5px'} key={idx}>
                                  <Text fontSize='0.813rem' fontWeight={500}>
                                    {attr?.name} :
                                  </Text>
                                  <Text
                                    color='gray'
                                    fontSize='0.813rem'
                                    fontWeight={500}
                                  >
                                    {attr?.value}
                                  </Text>
                                </Flex>
                              ))}
                              <Badge colorScheme='green' maxW='105px' mt='5px'>
                                {calculateDiscountPercentage(
                                  item.mrp,
                                  item.price
                                )}
                                % discount
                              </Badge>
                              <Flex gap={'5px'}>
                                {item.shipment === '0' ||
                                item.shipment === '0.00' ? (
                                  <Text
                                    fontSize='0.813rem'
                                    fontWeight={600}
                                    color='green'
                                  >
                                    Free Shipping
                                  </Text>
                                ) : (
                                  <>
                                    <Text fontSize='0.813rem' fontWeight={500}>
                                      Shipping :
                                    </Text>
                                    <Text
                                      color='gray'
                                      fontSize='0.813rem'
                                      fontWeight={500}
                                    >
                                      {currency} {item?.shipment}
                                    </Text>
                                  </>
                                )}
                              </Flex>
                            </Flex>
                          </Flex>
                        </Td>
                        <Td w='15%'>
                          <Text
                            fontSize={'14px'}
                            fontWeight={600}
                            color={'#000'}
                            whiteSpace='nowrap'
                          >
                            {`${currency} ${item.price}`}
                          </Text>
                        </Td>
                        <Td w='15%'>
                          <Box className={styles.qty_box} gap={0}>
                            <NumberGroupInput
                              defaultValue={item.quantity}
                              onChange={(value) =>
                                handleQuantityChange(item, value)
                              }
                            />
                          </Box>
                        </Td>
                        <Td w='15%'>
                          <Text
                            fontSize={'14px'}
                            fontWeight={600}
                            color={'#000'}
                            whiteSpace='nowrap'
                          >
                            {`${currency} ${calculateTotal(
                              formatePrice(item.price),
                              item.quantity
                            ).toLocaleString('en-US')}`}
                          </Text>
                        </Td>
                        <Td w='10%'>
                          <Flex gap={'10px'}>
                            <IconButton
                              onClick={() => handleAddToWishlistItem(item)}
                              aria-label='Add to wishlist'
                              icon={<RiHeartLine />}
                              variant={'outline'}
                            />
                            <IconButton
                              onClick={() => {
                                handleDeleteClick(item);
                                console.log(item, 'product detail');
                              }}
                              aria-label='Delete item'
                              icon={<RiDeleteBin2Line />}
                              variant={'outline'}
                            />
                            <IconButton
                              onClick={() => {
                                const _temp = {
                                  ...item,
                                  quantity: qty,
                                };
                                onShipping(_temp);
                              }}
                              aria-label='Shipment'
                              icon={<GoPackage />}
                              variant={'outline'}
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </Container>

            {/* Checkout Summary */}
            <Box
              className={styles.box}
              bg='#666666'
              p='1px'
              minW='23%'
              borderRadius='0.625rem'
              flexGrow={1}
            >
              <Box bg='white' p='10px' borderRadius='0.625rem'>
                <Text
                  mt='5px'
                  fontSize='18px'
                  fontWeight={700}
                  textAlign='start'
                >
                  Summary
                </Text>
                <Flex mt='20px' flexDir={'column'} gap={'.70rem'}>
                  <Flex justify={'space-between'} align={'center'}>
                    <Text color='#8c9097' fontSize='13px'>
                      Sub Total
                    </Text>
                    <Text fontSize='14px'>{cartDetails?.sub_total_amount}</Text>
                  </Flex>
                  <Flex justify={'space-between'} align={'center'}>
                    <Text color='#8c9097' fontSize='13px'>
                      Discount
                    </Text>
                    <Text color={'#26bf94'} fontSize='14px'>
                      {cartDetails?.discount}
                    </Text>
                  </Flex>
                  <Flex justify={'space-between'} align={'center'}>
                    <Text color='#8c9097' fontSize='13px'>
                      Delivery Charges
                    </Text>
                    <Text fontSize='14px'>{cartDetails?.shipment_amount}</Text>
                  </Flex>
                  <Flex justify={'space-between'} align={'center'}>
                    <Text color='#8c9097' fontSize='13px'>
                      Total :
                    </Text>
                    <Text fontWeight={600} fontSize='14px'>
                      {cartDetails?.total_amount}
                    </Text>
                  </Flex>
                </Flex>

                <Box mt={4}>
                  <Flex
                    justifyContent={'flex-start'}
                    gap={'5px'}
                    alignItems={'center'}
                  >
                    <Icon as={RiBox3Line} boxSize={4} color='gray.500' />
                    <Text fontWeight='600' fontSize='14px'>
                      Fast delivery
                    </Text>
                  </Flex>
                </Box>
                <Box mt={4}>
                  <Flex
                    justifyContent={'flex-start'}
                    gap={'5px'}
                    alignItems={'center'}
                  >
                    <Icon as={AiOutlineSafety} boxSize={4} color='gray.500' />
                    <Text fontWeight='600' fontSize='14px'>
                      Security & Privacy
                    </Text>
                  </Flex>
                  <Stack mt={2} spacing={1} marginLeft='25px'>
                    <Text fontSize='11px' mb={2} color='gray.500'>
                      Safe payments: We do not share your personal details with
                      any third parties without your consent.
                    </Text>
                    <Text fontSize='11px' color='gray.500'>
                      Secure personal details: We protect your privacy and keep
                      your personal details safe and secure.
                    </Text>
                  </Stack>
                </Box>
                <Box mt={4}>
                  <Flex
                    justifyContent={'flex-start'}
                    gap={'5px'}
                    alignItems={'center'}
                  >
                    <Icon as={GiThreeLeaves} boxSize={4} color='gray.500' />
                    <Text fontWeight='600' fontSize='14px'>
                      Buyer protection
                    </Text>
                  </Flex>
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  maxW='400px'
                  mt='20px'
                >
                  <Box display='flex' alignItems='center' w='100%'>
                    <Input
                      placeholder='Coupon Code'
                      flex='1'
                      borderRightRadius='0'
                      width='100%'
                      border='#e9edf6 1px solid'
                      value={coupon}
                      onChange={(e) => setCoupon(e.target.value)}
                    />
                    <Button
                      variant='secondary'
                      color='black'
                      maxW='150px'
                      borderLeftRadius='0'
                      // onClick={applyCoupon}
                    >
                      Apply
                    </Button>
                  </Box>
                </Box>

                <Button
                  w='100%'
                  mt='20px'
                  variant={'primary'}
                  onClick={() => {
                    navigate('/create/order');
                  }}
                >
                  Proceed to checkout
                </Button>
                <Button
                  w='100%'
                  mt='20px'
                  variant={'secondary'}
                  onClick={() => {
                    navigate('/product/listing');
                  }}
                >
                  Continue Shopping
                </Button>
              </Box>
            </Box>
          </Flex>
        </>
      )}

      <DeleteCartModal
        isOpen={isOpen}
        onClose={onClose}
        confirmDelete={confirmDelete}
      />

      <ShippingModal
        fromCart
        isOpen={isShippingOpen}
        onClose={onShippingClose}
        product={selectedItem}
      />
      <Modal width='95%' p='0 30px' isOpen={isOpenCart} onClose={onCloseCart}>
        <ProductDetail fromHome sku_id={productData?.sku_id} />
      </Modal>
    </div>
  );
};

export default Cart;
