import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchSubCategories } from '../../redux/productSlice';
import {
  Box,
  Container,
  Flex,
  Image,
  SimpleGrid,
  Skeleton,
  Text,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react';
import Recommendations from '../../components/home/recommendations';
import Modal from '../../components/modal';
import ProductDetail from '../product-detail';

const SubCategory = () => {
  const { id, name } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    isOpen: isOpenCart,
    onOpen: onOpenCart,
    onClose: onCloseCart,
  } = useDisclosure();

  const { subCategoriesList, isLoading } = useSelector(
    (state) => state.product
  );
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');

  useEffect(() => {
    if (id) dispatch(fetchSubCategories(id));
  }, [dispatch, id]);

  const cartHandler = (data) => {
    onOpenCart();
  };

  return (
    <Container maxW='100%' 
    // padding={'30px 120px'}
    >
      <Text fontSize={'22px'} fontWeight={600}>
        {name}
      </Text>
      <Box mt='1.25rem' mb='1.25rem'>
        <hr />
      </Box>
      {subCategoriesList.length === 0 && !isLoading && (
        <Flex align={'center'} justify={'center'} p={5}>
          <Text>No data found!</Text>
        </Flex>
      )}

      <SimpleGrid columns={isSmallerThan767 ? 2 : 5} spacing={'50px'}>
        {isLoading ? (
          <>
            {[0, 1, 2, 4, 5].map((item) => (
              <Skeleton
                bg='#F5F5F5'
                height={'160px'}
                w={'160px'}
                borderRadius={'50%'}
              ></Skeleton>
            ))}
          </>
        ) : (
          subCategoriesList.map((item) => (
            <Flex
              flexDir={'column'}
              align={'center'}
              justify={'center'}
              textAlign={'center'}
            >
              <Flex
                bg='#F5F5F5'
                height={'160px'}
                w={'160px'}
                borderRadius={'50%'}
                align={'center'}
                justify={'center'}
                cursor={'pointer'}
                onClick={() =>
                  navigate(`/product/listing?category_id=${item.id}`)
                }
              >
                <Image
                  height={'160px'}
                  w={'160px'}
                  borderRadius={'50%'}
                  border='2px #F5F5F5 solid'
                  _hover={{border:'2px red solid'}}
                  src={
                    item.image ||
                    require('../../assets/img/product/no-image.png')
                  }
                />
              </Flex>

              <Text
                color={'#000'}
                fontWeight={700}
                fontSize={'16px'}
                lineHeight={'20px'}
                mt={'20px'}
              >
                {item.name}
              </Text>
            </Flex>
          ))
        )}
      </SimpleGrid>

      <Recommendations
        title={
          <Text
            fontSize={'24px'}
            fontWeight={700}
            lineHeight={'30px'}
            color={'#666'}
            mb='30px'
            mt='40px'
          >
            Recommendations <span style={{ color: '#F41106' }}>For you</span>
          </Text>
        }
        onCartOpen={cartHandler}
      />
       <Modal width='95%' p='0 30px' isOpen={isOpenCart} onClose={onCloseCart}>
        <ProductDetail fromHome />
      </Modal>
    </Container>
  );
};

export default SubCategory;
