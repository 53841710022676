import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { useMediaQuery } from '@chakra-ui/react';
import ProductCard from '../../../components/product-card';

const Slider = ({ items, onCartOpen }) => {
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');
  console.log(items, 'items');
  return (
    <Swiper
      modules={[Pagination]}
      pagination={{ clickable: true }}
      // slidesPerView={isSmallerThan767 ? 1 : 5}
      slidesPerView={5}
      spaceBetween={isSmallerThan767 ? 8 : 16}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
      gridTemplateColumns={'repeat(auto-fit, minmax(225px, 1fr))'}
    >
      {items.map((item, index) => (
        <SwiperSlide key={index}>
          <ProductCard item={item} onCartOpen={onCartOpen} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;
