import { Box, Button, Center, Flex, Text, useToast } from '@chakra-ui/react';
import { Form, Formik } from 'formik';

import { useState } from 'react';
import * as Yup from 'yup';
import FormPasswordInput from '../../components/form-password-input';
import { RiCheckboxCircleLine, RiCloseCircleLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { changePasswordHandler } from '../../redux/userSlice';

const PasswordSetting = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);

  return (
    <Box p={'32px'} borderRadius={'4px'} border={'1px solid #f3f3f3'}>
      <Text fontWeight={600} fontSize={'16px'} mb='1.63rem'>
        {' '}
        Reset Password
      </Text>
      <Formik
        initialValues={{ pass: '', confirm: '' }}
        validationSchema={Yup.object({
          oldPassword: Yup.string().required(' '),
          pass: Yup.string()
            .required(' ')
            .matches(
              /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,32}$/,
              ' '
            ),
          confirm: Yup.string()
            .oneOf([Yup.ref('pass'), null], 'Passwords do not match.')
            .required(' '),
        })}
        onSubmit={(values) => {
          setIsLoading(true);
          const paylaod = {
            user_id: userData.id,
            current_password: values.oldPassword,
            password: values.pass,
          };
          dispatch(changePasswordHandler(paylaod)).then((res) => {
            if (res.status === 200) {
              toast({
                title: 'Password changed successfully',
                status: 'success',
              });
              localStorage.clear();
              window.location.href = '/';
            } else {
              toast({
                title: 'Password change failed',
                status: 'error',
              });
            }
          });
          setIsLoading(false);
        }}
        validateOnMount
      >
        {({ isValid, values }) => (
          <Flex as={Form} flexDir={'column'} gap={'30px'} w={'400px'}>
            <Flex flexDir={'column'} gap={'20px'}>
              <FormPasswordInput
                name='oldPassword'
                label='Current Password'
                placeholder='Current Password'
              />
              <FormPasswordInput
                name='pass'
                label='New Password'
                placeholder='New Password'
              />

              <Flex
                flexDir={'column'}
                gap={'5px'}
                textStyle={'body-rg'}
                color={'text.secondary.100'}
              >
                <Text my={'5px'}>Passwords must:</Text>

                <PasswordRequirement
                  isMet={values.pass.match(/^.{8,32}$/)}
                  label={'Be between 8 and 32 characters'}
                />
                <PasswordRequirement
                  isMet={values.pass.match(/^(?=.*?[A-Z])/)}
                  label={'Contain atleast 1 uppercase letter'}
                />
                <PasswordRequirement
                  isMet={values.pass.match(/^(?=.*?[0-9])/)}
                  label={'Contain atleast 1 number'}
                />
                <PasswordRequirement
                  isMet={values.pass.match(/^(?=.*?[#?!@$%^&*-])/)}
                  label={'Contain atleast 1 special character'}
                />
              </Flex>
            </Flex>

            <FormPasswordInput
              name='confirm'
              label='Confirm Password'
              placeholder='Confirm Password'
            />

            <Flex gap={'10px'} w={'100%'}>
              <Button
                variant={'primary'}
                type='submit'
                isDisabled={!isValid}
                w={'55%'}
                isLoading={isLoading}
                loadingText='Please wait...'
              >
                Update Password
              </Button>
            </Flex>
          </Flex>
        )}
      </Formik>
    </Box>
  );
};

export default PasswordSetting;

const PasswordRequirement = ({ label, isMet }) => {
  return (
    <Flex alignItems={'center'} w={'300px'} gap={'10px'}>
      <Center w={'20px'}>
        {isMet ? (
          <RiCheckboxCircleLine color='green' />
        ) : (
          <RiCloseCircleLine color='red' />
        )}
      </Center>

      <Flex fontSize={'14px'} pt={'3px'} textAlign={'left'}>
        {' '}
        {label}
      </Flex>
    </Flex>
  );
};
