import React from 'react';
import { Box, Text, VStack, Image, Button } from '@chakra-ui/react';
import SuccessImage from '../../../assets/img/product/pay-successful.png';
import { Link, useNavigate } from 'react-router-dom';
import { MdShoppingCart, MdShoppingCartCheckout } from 'react-icons/md';

const PaymentStatus = () => {
  const navigate = useNavigate();
  return (
    <Box backgroundColor='white'>
      <VStack p={4} width='80%' mx='auto' align='center' gap='30px'>
        <Text fontSize='24px' color='#26BF94' fontWeight='bold'>
          Payment successful...🤝
        </Text>
        <Box width={{ base: '100%', md: '25%' }} mx='auto'>
          <Image src={SuccessImage} width='80%' mx='auto' />
        </Box>
        <Box textAlign='center'>
          <Text>
            You can track your order
            {/* with Order Id{" "}
            <Text as="span" fontWeight="bold" color="black">
              SPK#1FR
            </Text>{" "} */}
            from{' '}
            <Text
              as='span'
              color='#26BF94'
              textDecoration='underline'
              _hover={{
                color: '#26BF94',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate('/my-orders');
              }}
            >
              Track Order
            </Text>
          </Text>
          <Text fontSize='14px' color='gray.500'>
            Thank you for shopping with us.
          </Text>
        </Box>
        <Box>
          <Button
            rightIcon={<MdShoppingCart />}
            color='white'
            backgroundColor='#26BF94'
          >
            Continue Shopping
          </Button>
        </Box>
      </VStack>
    </Box>
  );
};

export default PaymentStatus;
