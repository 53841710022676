export const percentage = (partialValue, totalValue) => {
  return (100 * parseFloat(partialValue)) / parseFloat(totalValue).toFixed(2);
};

// Function to calculate the discount percentage
export function calculateDiscountPercentage(
  originalPrice = 0,
  discountedPrice = 0
) {
  console.log(originalPrice, discountedPrice, 'calculateDiscountPercentage');

  if (!originalPrice && !discountedPrice) {
    return;
  }
  // Ensure originalPrice is greater than 0 to avoid division by zero
  //   if (originalPrice <= 0) {
  //     throw new Error('Original price must be greater than zero');
  //   }

  // Calculate the discount
  const discount =
    parseFloat(
      originalPrice.includes(',')
        ? originalPrice.replace(',', '')
        : originalPrice
    ) -
    parseFloat(
      discountedPrice.includes(',')
        ? discountedPrice.replace(',', '')
        : discountedPrice
    );

  console.log(discount, 'discount');

  // Calculate the discount percentage
  const discountPercentage =
    (discount /
      parseFloat(
        originalPrice.includes(',')
          ? originalPrice.replace(',', '')
          : originalPrice
      )) *
    100;

  // Return the result
  return Math.round(discountPercentage) || 0;
}
export function calculateDiscountPrice(originalPrice = 0, discountedPrice = 0) {
  if (!originalPrice && !discountedPrice) {
    return;
  }
  // Ensure originalPrice is greater than 0 to avoid division by zero
  //   if (originalPrice <= 0) {
  //     throw new Error('Original price must be greater than zero');
  //   }

  // Calculate the discount
  const discount =
    parseFloat(
      originalPrice.includes(',')
        ? originalPrice.includes(',', '')
        : originalPrice
    ) -
    parseFloat(
      discountedPrice.includes(',')
        ? discountedPrice.replace(',', '')
        : discountedPrice
    );

  // Return the result
  return discount.toFixed(2) || 0;
}

export const formatePrice = (price) => {
  return parseFloat(price.replace(',', ''));
};
