import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Flex,
  Input,
  SimpleGrid,
  Text,
  Select,
  Box,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import OrderCard from './order-card';
import { useDispatch, useSelector } from 'react-redux';
import { cancelOrderHandler, fetchOrders } from '../../redux/orderSlice';
import CancelOrder from './cancel-order';

const Orderlist = () => {
  const {
    isOpen: isOpenCancelOrder,
    onOpen: onOpenCancelOrder,
    onClose: onCloseCancelOrder,
  } = useDisclosure();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const { orders } = useSelector((state) => state.order);
  const [searchTerm, setSearchTerm] = useState('');
  const [orderId, setOrderId] = useState('');

  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <>
      {orders.length === 0 && (
        <Flex
          flexDir={'column'}
          gap={'10px'}
          justify={'center'}
          align={'center'}
          p='50px'
        >
          <Text fontSize={'24px'}>Your order list is empty!</Text>
          <Text>Add items to it now.</Text>
          <Button variant='primary' onClick={() => navigate('/')}>
            Shop Now
          </Button>
        </Flex>
      )}
      {orders.length > 0 && (
        <Box mb='30px'>
          <Flex py='20px' justify={'space-between'} align={'center'}>
            <Text fontSize={'1.125rem'} fontWeight={600}>
              Order List
            </Text>
          </Flex>
          <Flex
            pb='1.25rem'
            flexDir={{ base: 'column', md: 'row' }}
            className='box'
            justify={'space-between'}
            align={'center'}
          >
            <Text fontSize={'.9375rem'}>
              Total <Badge mx='5px'>{orders.length}</Badge> products are in the
              order list
            </Text>
            <Flex gap={'10px'}>
              <Select placeholder='Sort By' bg='#F9F9F9'>
                <option value='date'>Date</option>
                <option value='price'>Price</option>
                <option value='category'>Category</option>
              </Select>
              <Input
                placeholder='Search'
                value={searchTerm}
                onChange={handleSearchInputChange}
                focusBorderColor={'primary.100'}
              />
              <Button variant={'primary'}>Search</Button>
            </Flex>
          </Flex>
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3 }}
            gap='10px'
            alignItems='center'
            justifyItems='center'
          >
            {orders.map((order) => (
              <OrderCard
                key={order.id}
                order={order}
                onCancel={(id) => {
                  setOrderId(id);
                  onOpenCancelOrder();
                }}
              />
            ))}
          </SimpleGrid>
          <CancelOrder
            isOpen={isOpenCancelOrder}
            onClose={onCloseCancelOrder}
            confirmCancel={() => {
              dispatch(cancelOrderHandler(orderId)).then((res) => {
                if (res.status === 200) {
                  onCloseCancelOrder();
                  toast({
                    title: 'Order Cancelled',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                  });
                } else {
                  toast({
                    title: 'Something went wrong',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                  });
                }
              });
            }}
          />
        </Box>
      )}
    </>
  );
};

export default Orderlist;
