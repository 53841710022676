import {
  Box,
  Container,
  Flex,
  Skeleton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import Header from '../../components/header';
import Banner from '../../components/banner';
import Categories from '../../components/home/categories';
import Deals from '../../components/home/deals';
import TopCategory from '../../components/home/top-category';
import DealBanner1 from '../../assets/img/banner/banner-1.png';
import Recommendations from '../../components/home/recommendations';
import Footer from '../../components/footer';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchNowBought,
  getHomeDataHandler,
  getProductsHandler,
  setPage,
} from '../../redux/homeSlice';
import HotDealBanner from '../../components/deal-banner';
import BestDeals from '../../components/home/deals';
import Modal from '../../components/modal';
import ProductDetail from '../product-detail';
import { RiEdit2Line } from 'react-icons/ri';
import { BsChevronUp } from 'react-icons/bs';

import { PiChatCircleDotsLight } from 'react-icons/pi';
const dealBanner = [
  {
    url: DealBanner1,
  },
  {
    url: DealBanner1,
  },
  {
    url: DealBanner1,
  },
  {
    url: DealBanner1,
  },
  {
    url: DealBanner1,
  },
  {
    url: DealBanner1,
  },
];

const Homepage = () => {
  const {
    isOpen: isOpenCart,
    onOpen: onOpenCart,
    onClose: onCloseCart,
  } = useDisclosure();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);
  const { guestInfo } = useSelector((state) => state.guest);
  const { homeData, isLoading } = useSelector((state) => state.home);
  const [productData, setProductData] = useState(null);

  useEffect(() => {
    if (userData || guestInfo?.id) dispatch(getHomeDataHandler());
    return () => dispatch(setPage(1));
  }, [dispatch, guestInfo, guestInfo?.id, userData]);

  const cartHandler = (data) => {
    setProductData(data);
    onOpenCart();
  };

  useEffect(() => {
    dispatch(setPage(1));
  }, [dispatch]);

  useEffect(() => {
    setInterval(() => {
      dispatch(fetchNowBought());
    }, 120000); // 2 minutes = 120000 milliseconds
  }, [dispatch]);

  return (
    <Box bg='#ffffff'>
      <Categories />

      {isLoading ? (
        <Skeleton height={'316px'} borderRadius={'16px'}></Skeleton>
      ) : (
        <Banner data={homeData.banners} />
      )}

      <TopCategory />
      <HotDealBanner items={dealBanner} slidesPerView={3} spaceBetween={16} />
      <BestDeals
        title={
          <Text
            fontSize={'24px'}
            fontWeight={700}
            lineHeight={'30px'}
            color={'#666'}
          >
            The <span style={{ color: '#F41106' }}>best deal</span>
          </Text>
        }
        onCartOpen={cartHandler}
      />

      <Recommendations
        title={
          <Text
            fontSize={'24px'}
            fontWeight={700}
            lineHeight={'30px'}
            color={'#666'}
            mb='30px'
          >
            Recommendations <span style={{ color: '#F41106' }}>For you</span>
          </Text>
        }
        onCartOpen={cartHandler}
      />

      <Modal width='95%' p='0 30px' isOpen={isOpenCart} onClose={onCloseCart}>
        <ProductDetail fromHome sku_id={productData?.sku_id} />
      </Modal>
    </Box>
  );
};

export default Homepage;
