import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCountryList,
  fetchShipmentList,
} from '../../../redux/productSlice';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  FormControl,
  Select,
} from '@chakra-ui/react';
import {
  setCountry,
  setCurrency,
  setSelectedCountry,
} from '../../../redux/homeSlice';
import { currencyList } from '../../../utils/constants';

const CountryModal = ({ isOpen, onClose, onApply }) => {
  const dispatch = useDispatch();
  const { countries, country } = useSelector((state) => state.home);
  const { productDetails } = useSelector((state) => state.product);
  const product = productDetails?.products;
  const [selectedCountryCode, setSelectedCountryCode] = useState('');

  const handleCountryChange = (event) => {
    setSelectedCountryCode(event.target.value);
  };

  const applySelection = () => {
    const selectedCountryData = countries.find(
      (country) => country.country_code === selectedCountryCode
    );

    if (selectedCountryData) {
      const payload = {
        id: product.id,
        country: selectedCountryData.country_code,
        currency: product.currency,
        sku_id: product.sku_id,
        quantity: 1,
      };
      dispatch(fetchShipmentList(payload));
      onApply(selectedCountryData.name);
    }
    dispatch(setCountry(selectedCountryCode));
    dispatch(
      setCurrency(
        currencyList.find((c) => c.code.includes(selectedCountryCode)).code
      )
    );
    setSelectedCountryCode(
      countries?.find((j) => j.country_code === selectedCountryCode)
        ?.country_code
    );
    dispatch(
      setSelectedCountry(
        countries.find((c) => c.country_code === selectedCountryCode)
      )
    );
    onClose();
  };

  useEffect(() => {
    setSelectedCountryCode(
      countries?.find((j) => j.country_code === country || 'TZ')?.country_code
    );
  }, [countries, country]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xs'>
      <ModalOverlay />
      <ModalContent mx='auto' my='auto' maxW='300px'>
        <ModalHeader fontSize='16px' fontWeight='700'>
          Ship to
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody mt={-3}>
          <FormControl mb={2}>
            <Select
              placeholder='Select country'
              onChange={handleCountryChange}
              borderRadius='8px'
              value={selectedCountryCode}
            >
              {countries?.map((country) => (
                <option key={country.country_code} value={country.country_code}>
                  {country.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <Button
            colorScheme='red'
            onClick={applySelection}
            width='100%'
            borderRadius='20px'
          >
            Apply
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CountryModal;
