import { Box, Divider, Flex, Icon, Text } from "@chakra-ui/react";
import {
  FaCheckCircle,
  FaBox,
  FaTruck,
  FaShippingFast,
  FaCircle,
} from "react-icons/fa";
import { GiShoppingCart } from "react-icons/gi";
import { RiBox3Line } from "react-icons/ri";

const OrderTracking = () => {
  return (
    <Box
      bg="white"
      borderRadius="md"
      boxShadow="md"
      width={{ base: "100%", md: "350px" }}
      // mb={{ base: "10px", md: "266px" }}
      zIndex="-2"
    >
      <Flex
        flexDir="row"
        borderBottom="2px solid gray.200"
        justifyContent="space-between"
        alignItems="center"
        p="15px"
      >
        <Flex mt="10px">
          <Box
            width="4px"
            height="24px"
            bgGradient="linear(to-b, #B5B8F0, #A5C7F1)"
            mr={2}
            borderRadius="5px"
          />
          <Text fontSize="18px" fontWeight="bold" mb={2}>
            Order No
          </Text>
        </Flex>
        <Text color="#2AC096" fontSize="14px">
          #SPK1218153635
        </Text>
      </Flex>
      <Divider mb="4" />
      <Flex direction="column" position="relative" pl="10" mt="20px">
        {/* Track line */}
        <Box
          position="absolute"
          left="58px"
          top="0"
          bottom="25px"
          zIndex={"-1"}
          width="1px"
          borderLeft="2px dashed gray"
        />
        {/* Order Placed */}
        <Flex mb="6" flexDir="row">
          <Flex
            height="35px"
            width="35px"
            bg="#239FDB"
            color="white"
            borderRadius="50px"
            padding="7px"
            alignItems="center"
            justifyContent="center"
          >
            <GiShoppingCart size="24px" bg="blue" />
          </Flex>
          <Box ml="4">
            <Text fontWeight="bold" fontSize="sm">
              Order Placed
            </Text>
            <Text fontSize="13px"  mb="3" fontWeight="600" color="#2AC096">
              Nov 03, 2022
            </Text>
            <Text fontSize="13px" color="gray.500">
              Order placed successfully by  <Text as ="span" cursor="pointer" color="#E6533C">Sansa Taylor</Text> 
            </Text>
            <Text fontSize="13px" color="#DCDDDF" mb="3">
              Nov 03, 2022, 15:36
            </Text>
          </Box>
        </Flex>

        {/* Picked */}
        <Flex mb="6" flexDir="row">
          <Icon
            as={FaCheckCircle}
            boxSize="34px"
            color="green.500"
            bg="white"
          />
          <Box ml="4">
            <Text fontWeight="bold" fontSize="sm">
              Picked
            </Text>
            <Text fontSize="13px" color="gray.500" fontWeight="600" mb="3">
              Nov 03, 15:10
            </Text>
            <Text fontSize="13px" color="gray.500">
              Your order has been picked up by Smart Good Services
            </Text>
            <Text fontSize="13px" color="#DCDDDF" mb="3">Nov 3, 2022, 15:10</Text>
          </Box>
        </Flex>

        {/* Shipping */}
        <Flex mb="6" flexDir="row">
          <Flex
            // display="inline-block"
            height="35px"
            width="35px"
            bg="#F06B6B"
            color="white"
            borderRadius="50px"
            padding="7px"
            alignItems="center"
            justifyContent="center"
          >
            <FaTruck size="24px" bg="blue" />
          </Flex>
          <Box ml="4">
            <Text fontWeight="bold" fontSize="sm">
              Shipping
            </Text>
            <Text fontSize="13px" color="gray.500" fontWeight="600" mb="3">
              Nov 03, 15:10
            </Text>
            <Text fontSize="13px" color="gray.500" mb="3">
              Arrived USA SGS Warehouse
            </Text>
            <Text fontSize="13px" color="gray.500" >
              picked up by SGS Agent and on the way to Hyderabad
            </Text>
            <Text fontSize="13px" color="#DCDDDF" mb="3">
            Nov 03, 2022, 15:36
            </Text>
            <Text fontSize="13px" color="gray.500">
              Arrived in Hyderabad and expected Delivery is Apr 16, 2022
            </Text>
            <Text fontSize="13px" color="#DCDDDF" mb="3">
            Nov 03, 2022, 15:36
            </Text>
          </Box>
        </Flex>

        {/* Out for Delivery */}
        <Flex mb="6" flexDir="row">
          <Flex
            height="43px"
            width="43px"
            bg="#F2EEFC"
            color="#E6533C"
            borderRadius="50px"
            padding="7px"
            alignItems="center"
            justifyContent="center"
          >
            <RiBox3Line size="20px" bg="blue" />
          </Flex>
          <Box ml="4">
          <Text fontSize="sm"  fontWeight="bold" >
              Out For Delivery
            </Text>
            <Text fontSize="13px" color="gray.500" fontWeight="600" mb="3">
              Nov 03, 15:10 (expected)
            </Text>
          </Box>
        </Flex>

        {/* Delivered */}
        <Flex mb="6" flexDir="row">
          <Flex
            height="43px"
            width="43px"
            bg="#F2EEFC"
            color="#E6533C"
            borderRadius="50px"
            padding="7px"
            alignItems="center"
            justifyContent="center"
            border="1px solid #F2EDFC"
          >
            <RiBox3Line size="20px" bg="blue" />
          </Flex>
          <Box ml="4">
            <Text fontWeight="bold" fontSize="sm">
              Delivered
            </Text>
            <Text fontSize="13px" color="gray.500" fontWeight="600" >
              Nov 03, 18:42
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default OrderTracking;
