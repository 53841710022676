import React, { useState } from 'react';
import './index.scss';
import { Button, Flex, Text, useToast } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import FormInput from '../../components/form-input';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { sendOtp, verifyOtp, resetPassword } from '../../redux/authSlice';

const ForgotPasswordPage = () => {

    //  Showing UI based on three steps for reseting password
    //  1: Enter Email, 
    //  2: Enter OTP, 
    //  3: Enter New Password


  const [step, setStep] = useState(1); 
  const [email, setEmail] = useState("")
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();

  const handleEmailSubmit = (values, { setSubmitting }) => {
    setEmail(values.email);
    dispatch(sendOtp({ email: values.email }))
      .then((response) => {
        // console.log(response.response.data, 'response of data')
        console.log(response, 'response of data')
        setSubmitting(false);
        if (response.data.success && response.data.statusCode === 200) {
          toast({
            title: response.data.message || 'OTP sent successfully',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          setStep(2);
        }
         else {
          throw new Error(response.response.data.message || 'Something went wrong');
        }
      })
      .catch((error) => {
        setSubmitting(false);
        const errorMessage = error.message || 'Something went wrong';
        toast({
          title: errorMessage,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleOtpSubmit = (values, { setSubmitting }) => {
    dispatch(verifyOtp({ otp: values.otp, email }))  
      .then((response) => {
        setSubmitting(false);
        if (response.data.success && response.data.statusCode === 200) {
          toast({
            title: response.data.message || 'OTP sent successfully',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          setStep(3);
        }
        else {
          throw new Error(response.response.data.message || 'Something went wrong');
        }
      })
      .catch((error) => {
        setSubmitting(false);
        toast({
          title: error.response?.data?.message ?? 'Invalid OTP',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handlePasswordReset = (values, { setSubmitting }) => {
    dispatch(resetPassword({ email, password: values.password }))  
      .then((response) => {
        setSubmitting(false);
        if (response.data.success && response.data.statusCode === 200) {
          toast({
            title: response.data.message || 'New password set successfully',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          localStorage.clear();
          window.location.href = '/';
        }
        else {
          throw new Error(response.response.data.message || 'Failed setting new password');
        }
      })
      .catch((error) => {
        setSubmitting(false);
        toast({
          title: error.response?.data?.message ?? 'Something went wrong',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <div className='recover-container'>
      <div className='recover-box'>
        {/* <h2> Password Recovery</h2> */}
        {step === 1 && (
          <Formik
            initialValues={{ email: '' }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email('Invalid email address')
                .required('Required'),
            })}
            onSubmit={handleEmailSubmit}
            validateOnMount
          >
            {({ isValid }) => (
              <Flex flexDir={'column'} as={Form} gap={'1.25em'} mt='1.26rem'>
                <h2> Password Recovery</h2>
                <FormInput
                  name='email'
                  type='text'
                  label='Email Address'
                  placeholder='Email Address'
                />
                <Button
                  variant={'primary'}
                  isDisabled={!isValid}
                  size={'sm'}
                  type='submit'
                  w={'200px'}
                >
                  Send OTP
                </Button>
              </Flex>
            )}
          </Formik>
        )}
        {step === 2 && (
          <Formik
            initialValues={{ otp: '' }}
            validationSchema={Yup.object({
              otp: Yup.string().required('Required'),
            })}
            onSubmit={handleOtpSubmit}
            validateOnMount
          >
            {({ isValid }) => (
              <Flex flexDir={'column'} as={Form} gap={'1.25em'} mt='1.26rem'>
                <h2> Password Recovery</h2>
                <FormInput
                  name='otp'
                  type='text'
                  label='OTP'
                  placeholder='Enter OTP'
                />
                <Button
                  variant={'primary'}
                  isDisabled={!isValid}
                  size={'sm'}
                  type='submit'
                  w={'200px'}
                >
                  Verify OTP
                </Button>
              </Flex>
            )}
          </Formik>
        )}
        {step === 3 && (
          <Formik
            initialValues={{ password: '', confirmPassword: '' }}
            validationSchema={Yup.object({
              password: Yup.string().required('Required'),
              confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Required'),
            })}
            onSubmit={handlePasswordReset}
            validateOnMount
          >
            {({ isValid }) => (
              <Flex flexDir={'column'} as={Form} gap={'1.25em'} mt='1.26rem'>
                <h2> Password Recovery</h2>
                <FormInput
                  name='password'
                  type='password'
                  label='New Password'
                  placeholder='Enter new password'
                />
                <FormInput
                  name='confirmPassword'
                  type='password'
                  label='Confirm Password'
                  placeholder='Confirm new password'
                />
                <Button
                  variant={'primary'}
                  isDisabled={!isValid}
                  size={'sm'}
                  type='submit'
                  w={'200px'}
                > 
                   Save Password
                </Button>
              </Flex>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
