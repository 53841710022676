import axios from 'axios';

export const getHomeData = async (country, currency, user_id) => {
  try {
    return await axios.get(
      `/home-data?country=${country}&currency=${currency}&user_id=${user_id}`
    );
  } catch (error) {
    return error;
  }
};
export const getAllCategories = async (country, currency) => {
  try {
    return await axios.get(
      `/category-list?country=${country}&currency=${currency}`
    );
  } catch (error) {
    return error;
  }
};
export const getProducts = async (_params, country, currency, user_id) => {
  try {
    // query params
    const params = {
      ..._params,
      country,
      currency,
      user_id,
    };

    return await axios.get(`/product-list`, {
      params,
    });
  } catch (error) {
    return error;
  }
};

export const getCountryList = async () => {
  try {
    return await axios.get(`get-country`);
  } catch (error) {
    return error;
  }
};
export const getNowBought = async () => {
  try {
    return await axios.get(`get-now-bought`);
  } catch (error) {
    return error;
  }
};
