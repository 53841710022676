import React, { useState } from 'react';
import {
  Box,
  Text,
  Link,
  Button,
  VStack,
  HStack,
  Image,
  Icon,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import productImage from '../../../assets/img/product/25.png';
import Logistics from '../logistics';
import { useDispatch, useSelector } from 'react-redux';
import { addToCartHandler, fetchCartList } from '../../../redux/cartSlice';
import { useNavigate } from 'react-router-dom';
import { addToWishlistHandler } from '../../../redux/wishlistSlice';
import NumberGroupInput from '../../../components/number-group-input';
import isEmpty from 'is-empty';

const OfferSection = ({ fromHome }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const { productDetails, selectedShipment } = useSelector(
    (state) => state.product
  );
  const { userData } = useSelector((state) => state.user);
  const { guestInfo } = useSelector((state) => state.guest);
  const [qty, setQty] = useState(1);
  const [cartLoading, setCartLoading] = useState(false);
  const [wishlistLoading, setWishlistLoading] = useState(false);

  const product = productDetails?.products;

  return (
    <VStack spacing={4} align='start'>
      {/* {!fromHome && (
        <Box>
          <Text fontWeight='bold'>Returns:</Text>
          <Text fontSize='sm'>
            Min 7 days return and exchange policy. Return Policies may vary
            based on products and promotions. For full details on our Returns
            Policies, please <Link color='blue.500'>click here.</Link>
          </Text>
        </Box>
      )} */}

      <Box style={{ width: '100%' }} mt={5}>
        <Button
          opacity={product?.quantity === 0 ? 0.5 : 1}
          pointerEvents={product?.quantity === 0 ? 'none' : 'auto'}
          isDisabled={isEmpty(product)}
          style={{
            width: '100%',
            backgroundColor: '#fa1819',
            color: 'white',
            marginBottom: '10px',
          }}
          onClick={() => {
            setCartLoading(true);
            const payload = {
              ...productDetails?.products,
              logistic_service: selectedShipment?.logistic_service,
              shipping: selectedShipment?.free_shipping
                ? 0
                : selectedShipment?.shipment_fee,
              quantity: qty,
            };
            dispatch(addToCartHandler(payload)).then((res) => {
              setCartLoading(false);
              if (res.status === 200) {
                toast({
                  title: 'Product added to cart',
                  status: 'success',
                  duration: 2000,
                  isClosable: true,
                });
                dispatch(fetchCartList(userData?.id || guestInfo.id));
              }
            });
          }}
        >
          {cartLoading ? <Spinner size='sm' /> : 'Add To Cart'}
          {/* Add To Cart */}
        </Button>
        <Button
          opacity={product?.quantity === 0 ? 0.5 : 1}
          pointerEvents={product?.quantity === 0 ? 'none' : 'auto'}
          style={{
            width: '100%',
            marginBottom: '10px',
          }}
          variant='secondary'
          onClick={() => {
            navigate('/create/order');
          }}
          isDisabled={isEmpty(product)}
        >
          Buy Now
        </Button>
        <Button
          variant='secondary'
          style={{
            width: '100%',
            marginBottom: '10px',
          }}
          onClick={() => {
            setWishlistLoading(true);
            dispatch(addToWishlistHandler(productDetails?.products)).then(
              (res) => {
                setWishlistLoading(false);
                if (res.status === 200) {
                  toast({
                    title: 'Product added to wishlist',
                    status: 'success',
                    duration: 2000,
                    isClosable: true,
                  });
                }
              }
            );
          }}
          isDisabled={isEmpty(product)}
        >
          {wishlistLoading ? <Spinner size='sm' /> : 'Add To Wishlist'}
          {/* Add To Wishlist */}
        </Button>
      </Box>

      {/* <Box
        style={{
          width: '100%',
          textAlign: 'center',
          marginTop: '35px',
          borderRadius: '8px',
        }}
        backgroundColor='#F3F6F8'
      >
        <Image
          src={productImage}
          alt='Offer'
          style={{ width: '100%', borderRadius: '8px' }}
        />
      </Box> */}
    </VStack>
  );
};

export default OfferSection;
