import {
  Box,
  Flex,
  Image,
  SimpleGrid,
  Text,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSubCategories, setSubSubCategories } from '../../redux/homeSlice';
import { useNavigate } from 'react-router-dom';

const SubCategories = ({ title, onCloseCategory }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { subCategories, subSubCategories } = useSelector(
    (state) => state.home
  );
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');
  const [isBiggerThan767] = useMediaQuery('(min-width: 767px)');
  return (
    <Flex>
      <Flex flexDir='column' width='auto'>
        <Text
          color={'#F41106'}
          fontWeight={600}
          textTransform={'uppercase'}
          mb='10px'
          pl={10}
        >
          {title}
        </Text>
        <SimpleGrid
          columns={isBiggerThan767 ? 4 : 5}
          spacing={'20px'}
          pr={'20px'}
          w={'auto'}
          overflowX={'hidden'}
          maxHeight={'465px'}
          overflowY={'auto'}
          pl='40px'
          borderRight={'1px solid #E2E2E2'}
          // mt='38px'
        >
          {subCategories.map((item, index) => (
            <>
              <Flex
                flexDir={'column'}
                align={'center'}
                textAlign={'center'}
                mb='25px'
                onClick={() => {
                  onCloseCategory(false);
                  navigate(`/product/category/${item.id}/${item.name}`);
                }}
              >
                <Flex
                  borderRadius={'50%'}
                  align={'center'}
                  justify={'center'}
                  border={item.isActive ? '1.5px solid #F41106' : 'none'}
                  bg={'#F5F5F5'}
                  height={'100px'}
                  width={'100px'}
                  padding={'12.5px 30px'}
                  onMouseEnter={() => {
                    const temp = subCategories.map((innerItem, innerIndex) => ({
                      ...innerItem,
                      isActive: innerIndex === index,
                    }));

                    dispatch(setSubCategories(temp));
                    dispatch(setSubSubCategories(item.subcat_data));
                  }}
                  onMouseLeave={() => {
                    const temp = subCategories.map((innerItem, innerIndex) => ({
                      ...innerItem,
                      isActive: innerIndex === index,
                    }));

                    dispatch(setSubCategories(temp));
                  }}
                  cursor={'pointer'}
                >
                  <Image
                    height={'97px'}
                    // w={'60px'}
                    minW={'97px'}
                    border={'1px solid #F5F5F5'}
                    borderRadius={'50%'}
                    src={
                      item.image ||
                      require('../../assets/img/product/no-image.png')
                    }
                  />
                </Flex>

                <Tooltip label={item.name} aria-label='A tooltip'>
                  <Text
                    color={'#000'}
                    fontWeight={700}
                    fontSize={'14px'}
                    lineHeight={'20px'}
                    mt={'15.59px'}
                    whiteSpace={'nowrap'}
                    overflow={'hidden'}
                    textOverflow={'ellipsis'}
                    maxW={'100px'}
                  >
                    {item.name}
                  </Text>
                </Tooltip>
              </Flex>
            </>
          ))}
        </SimpleGrid>
      </Flex>
      <Box w={'50%'} pl='20px'>
        {/* <Text color={'#F41106'} textTransform={'uppercase'} mb='20px'>
          {title}
        </Text> */}
        <Box
          overflowX={'hidden'}
          minH={'465px'}
          maxHeight={'500px'}
          overflowY={'auto'}

          // spacing={'20px'}
          // pr={'20px'}
          // w={'auto'}
          // pl='40px'
          // borderRight={'1px solid #E2E2E2'}
        >
          {' '}
          {subCategories.map((item, index) => (
            <>
              <Flex flexDir={'column'} mb='15px'>
                <Text fontWeight={600} mb='10px'>
                  {item.name}
                </Text>
                <SimpleGrid columns={isBiggerThan767 ? 4 : 5} spacing={'20px'}>
                  {item.subcat_data.map((catItem, catIndex) => (
                    <Flex
                      flexDir={'column'}
                      align={'center'}
                      textAlign={'center'}
                      mb='25px'
                      onClick={() => {
                        onCloseCategory(false);
                        navigate(`/product/listing?category_id=${catItem.id}`);
                      }}
                      cursor={'pointer'}
                    >
                      <Flex
                        borderRadius={'50%'}
                        align={'center'}
                        justify={'center'}
                        border={
                          catItem.isActive ? '1.5px solid #F41106' : 'none'
                        }
                        bg={'#F5F5F5'}
                        height={'100px'}
                        width={'100px'}
                        padding={'12.5px 30px'}
                        onMouseEnter={() => {
                          const temp = subSubCategories.map(
                            (innerItem, innerIndex) => ({
                              ...innerItem,
                              isActive: innerIndex === catIndex,
                            })
                          );

                          dispatch(setSubSubCategories(temp));
                        }}
                        onMouseLeave={() => {
                          const temp = subSubCategories.map(
                            (innerItem, innerIndex) => ({
                              ...innerItem,
                              isActive: innerIndex === catIndex,
                            })
                          );

                          dispatch(setSubSubCategories(temp));
                        }}
                      >
                        <Image
                          height={'97px'}
                          // w={'60px'}
                          minW={'97px'}
                          border={'1px solid #F5F5F5'}
                          borderRadius={'50%'}
                          src={
                            catItem.image ||
                            require('../../assets/img/product/no-image.png')
                          }
                        />
                      </Flex>

                      <Tooltip label={catItem.name} aria-label='A tooltip'>
                        <Text
                          color={'#000'}
                          fontWeight={400}
                          fontSize={'14px'}
                          lineHeight={'20px'}
                          mt={'15.59px'}
                          whiteSpace={'nowrap'}
                          overflow={'hidden'}
                          textOverflow={'ellipsis'}
                          maxW={'100px'}
                        >
                          {catItem.name}
                        </Text>
                      </Tooltip>
                    </Flex>
                  ))}
                </SimpleGrid>
              </Flex>
            </>
          ))}
        </Box>
      </Box>
    </Flex>
  );
};

export default SubCategories;
