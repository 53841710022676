import React, { useEffect, useState } from 'react';
import { Button, HStack, Input, useNumberInput } from '@chakra-ui/react';

const NumberGroupInput = ({ onChange }) => {
  const [value, setValue] = useState(1);

  const onDecrementClick = () => {
    if (value <= 1) return;
    setValue(parseInt(value) - 1);
    onChange(parseInt(value) - 1);
  };
  const onIncrementClick = () => {
    setValue(parseInt(value) + 1);
    onChange(parseInt(value) + 1);
  };
  return (
    <HStack>
      <Button
        maxWidth='35px'
        height={'30px'}
        width={'35px'}
        onClick={onDecrementClick}
      >
        -
      </Button>
      <Input
        onChange={(e) => {
          if (e.target.value < 1) return;
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        value={value}
        textAlign={'center'}
        padding='0'
        maxWidth='45px'
        minWidth='40px'
        width={'45px'}
        height={'30px'}
      />
      <Button
        maxWidth='35px'
        height={'30px'}
        width={'35px'}
        onClick={onIncrementClick}
      >
        +
      </Button>
    </HStack>
  );
};

export default NumberGroupInput;
