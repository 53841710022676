import React from 'react';
import './index.scss';
import ProductListing from '../../components/product-listing';
import { Box } from '@chakra-ui/react';
import Filters from '../../components/filters';
import SortBy from '../../components/sortby';

const Listing = () => {
  return (
    <div className='product-listing'>
      <Box width={'15%'} p={'25px 0px 25px 0'} minW={'245px'}>
        <Filters />
      </Box>
      <Box p={'25px 0 0 25px'} flex={1}>
        <SortBy />
        <ProductListing />
      </Box>
    </div>
  );
};

export default Listing;
