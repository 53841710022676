import React from "react";
import {
  Box,
  Avatar,
  Text,
  Stack,
  Flex,
  Circle,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import Profile from "../../assets/img/product/profileImage.jpg";

const NotificationCard = ({ notification }) => (
  <Box
    bg="white"
    borderWidth="1px"
    borderRadius="lg"
    overflow="hidden"
    p={4}
    mb={4}
    boxShadow="md"
    width="100%"
    minH="100px"
    position="relative"
    _before={{
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      width: "4px",
      bg: "blue.500",
      borderRadius: "full",
      boxShadow: "lg",
    }}
  >
    <Flex alignItems="start" mb={2} flexDir={{ base: "column", md: "row" }} >
      <Box position="relative">
        <Avatar
          size="md"
          name={notification.name}
          src={Profile}
          mr={3}
        />
        <Circle
          size="13px"
          bg={notification.active ? "green.400" : "gray.400"}
          border="2px solid white"
          position="absolute"
          bottom={-1}
          right={4}
        />
      </Box>
      <Stack direction="column" spacing={0} flex="1">
        <Text fontWeight="bold">{notification.name}</Text>
        <Flex alignItems="center">
          <Text color="#696969">{notification.message}</Text>
          {notification.name === "Melissa Blue" && (
            <CheckCircleIcon color="green.500" ml={2} />
          )}
        </Flex>
      </Stack>
    </Flex>
    <Flex justify="space-between" align="center">
      <Text fontSize="sm" color="gray.500">
        {notification.time}
      </Text>
      <Text
        fontSize="sm"
        color="gray.500"
        bg="gray.100"
        px={2}
        py={1}
        borderRadius="md"
      >
        {notification.date}
      </Text>
    </Flex>
  </Box>
);

export default NotificationCard;
