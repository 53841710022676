import React, { useState } from "react";
import {
  ChakraProvider,
  Box,
  VStack,
  Spinner,
  Text,
  Flex,
} from "@chakra-ui/react";
import NotificationCard from "./notification-card";
import { RiArrowRightDoubleFill } from "react-icons/ri";

const notifications = [
  {
    id: 1,
    name: "Emperio",
    message: "Project assigned by the manager all files and folders were included",
    time: "12 mins ago",
    date: "24, Oct 2024",
    active: true,
  },
  {
    id: 2,
    name: "Dwayne Bero",
    message: "Admin and other team accepted your work request",
    time: "17 mins ago",
    date: "30, Sep 2024",
    active: true,
  },
  {
    id: 3,
    name: "Alister Chuk",
    message: "Temporary data will be deleted once dedicated time complated",
    time: "4 hrs ago",
    date: "11, Sep 2024",
    active: false,
  },
  {
    id: 4,
    name: "Melissa Blue",
    message: "Approved date for sanction of loan is verified",
    time: "5 hrs ago",
    date: "18, Sep 2024",
    active: true,
  },
];

const NotificationList = () => {
  const [loading, setLoading] = useState(true); // Set initial loading state to true

  return (

    <Box>
    <Flex
      flexDir={{ base: "column", md: "row" }}
      alignItems={{ base: "flex-start", md: "center" }}
      justifyContent={{ base: "flex-start", md: "space-between" }}
      p="25px 20px 0px 20px"
    >
      <Text fontSize="20px" fontWeight="bold">
        Notifications
      </Text>
      <Flex alignItems="center" gap="10px">
        <Text color="#E6533C" fontSize="14px">
          Pages
        </Text>
        <RiArrowRightDoubleFill color="#E6533C" />
        <Text fontWeight="bold" fontSize="14px">
          Notifications
        </Text>
      </Flex>
    </Flex>
      <Box p={4} m="10px"  borderRadius="10px">
        <VStack spacing={4}>
          {notifications.map((notification, index) => (
            <React.Fragment key={notification.id}>
              <NotificationCard notification={notification} />
              {index === 3 && loading && (
                <Box textAlign="center">
                  <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
                </Box>
              )}
            </React.Fragment>
          ))}
        </VStack>
      </Box>
      </Box>
  );
};

export default NotificationList;
