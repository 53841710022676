// import {
//   Accordion,
//   AccordionButton,
//   AccordionIcon,
//   AccordionItem,
//   AccordionPanel,
//   Box,
//   Button,
//   Checkbox,
//   Flex,
//   Input,
//   Radio,
//   RadioGroup,
//   Stack,
//   useToast,
// } from '@chakra-ui/react';
// import React, { useEffect, useState } from 'react';
// import styles from './filters.module.scss';
// import { useQuery } from '../../hooks/useQuery';
// import { useDispatch } from 'react-redux';
// import { getProductsHandler } from '../../redux/homeSlice';

// const Filters = () => {
//   const dispatch = useDispatch();
//   const query = useQuery();
//   const toast = useToast();
//   const categoryId = query.get('category_id');
//   const page_no = query.get('page_no');

//   const is_free_shipping = query.get('is_free_shipping');
//   const [isFreeShipping, setIsFreeShipping] = useState(false);
//   const [sale, setSale] = useState(false);
//   const [min, setMin] = useState(null);
//   const [max, setMax] = useState(null);
//   const [rating, setRating] = useState(null);

//   useEffect(() => {
//     setIsFreeShipping(is_free_shipping === '1');
//   }, [is_free_shipping]);

//   return (
//     <Accordion
//       className={styles.accordion}
//       defaultIndex={[0, 1, 2, 3]}
//       allowMultiple
//     >
//       <AccordionItem className={styles.item}>
//         <h2>
//           <AccordionButton className={styles.button}>
//             <Box as='span' flex='1' textAlign='left'>
//               Delivery options & services
//             </Box>
//             <AccordionIcon />
//           </AccordionButton>
//         </h2>
//         <AccordionPanel pb={4} className={styles.panel}>
//           <Checkbox
//             isChecked={isFreeShipping}
//             onChange={(e) => {
//               dispatch(
//                 getProductsHandler(
//                   page_no || 1,
//                   categoryId,
//                   null,
//                   null,
//                   null,
//                   e.target.checked ? 1 : 0,
//                   sale ? 1 : 0,
//                   null,
//                   null
//                 )
//               );
//               setIsFreeShipping(e.target.checked);
//             }}
//           >
//             Free Shipping
//           </Checkbox>
//         </AccordionPanel>
//       </AccordionItem>
//       <AccordionItem className={styles.item}>
//         <h2>
//           <AccordionButton className={styles.button}>
//             <Box as='span' flex='1' textAlign='left'>
//               Discounts
//             </Box>
//             <AccordionIcon />
//           </AccordionButton>
//         </h2>
//         <AccordionPanel pb={4} className={styles.panel}>
//           <Checkbox
//             onChange={(e) => {
//               dispatch(
//                 getProductsHandler(
//                   page_no || 1,
//                   categoryId,
//                   null,
//                   null,
//                   null,
//                   isFreeShipping ? 1 : 0,
//                   e.target.checked ? 1 : 0,
//                   null,
//                   null
//                 )
//               );
//               setSale(e.target.checked);
//               setMin(null);
//               setMax(null);
//             }}
//           >
//             Sale
//           </Checkbox>
//         </AccordionPanel>
//       </AccordionItem>
//       <AccordionItem className={styles.item}>
//         <h2>
//           <AccordionButton className={styles.button}>
//             <Box as='span' flex='1' textAlign='left'>
//               Price
//             </Box>
//             <AccordionIcon />
//           </AccordionButton>
//         </h2>
//         <AccordionPanel pb={4} className={styles.panel}>
//           <Flex align={'center'} gap={'5px'}>
//             <Input
//               onChange={(e) => {
//                 setMin(e.target.value);
//               }}
//               value={min}
//               width={'45%'}
//               type='text'
//               placeholder='Min'
//             />
//             <Box flex={1}>-</Box>
//             <Input
//               onChange={(e) => {
//                 setMax(e.target.value);
//               }}
//               value={max}
//               width={'45%'}
//               type='text'
//               placeholder='Max'
//             />
//             <Button
//               onClick={() => {
//                 console.log(min, 'min');
//                 if (!min) {
//                   toast({
//                     title: 'Error',
//                     description: 'Please enter min price',
//                     status: 'error',
//                     duration: 9000,
//                     isClosable: true,
//                   });
//                   return;
//                 }
//                 if (!max) {
//                   toast({
//                     title: 'Error',
//                     description: 'Please enter max price',
//                     status: 'error',
//                     duration: 9000,
//                     isClosable: true,
//                   });
//                   return;
//                 }

//                 if (min > max) {
//                   toast({
//                     title: 'Error',
//                     description: 'Min price should be less than max price',
//                     status: 'error',
//                     duration: 9000,
//                     isClosable: true,
//                   });
//                   return;
//                 }
//                 if (min > max) {
//                   toast({
//                     title: 'Error',
//                     description: 'Min price should be less than max price',
//                     status: 'error',
//                     duration: 9000,
//                     isClosable: true,
//                   });
//                   return;
//                 }
//                 dispatch(
//                   getProductsHandler(
//                     page_no || 1,
//                     categoryId,
//                     null,
//                     null,
//                     null,
//                     isFreeShipping ? 1 : 0,
//                     sale ? 1 : 0,
//                     min,
//                     max
//                   )
//                 );
//               }}
//               width={'30px'}
//               variant={'primary'}
//             >
//               OK
//             </Button>
//           </Flex>
//         </AccordionPanel>
//       </AccordionItem>
//       <AccordionItem className={styles.item}>
//         <h2>
//           <AccordionButton className={styles.button}>
//             <Box as='span' flex='1' textAlign='left'>
//               Rating
//             </Box>
//             <AccordionIcon />
//           </AccordionButton>
//         </h2>
//         <AccordionPanel pb={4} className={styles.panel}>
//           <RadioGroup
//             onChange={(value) => {
//               dispatch(
//                 getProductsHandler(
//                   page_no || 1,
//                   categoryId,
//                   null,
//                   null,
//                   null,
//                   isFreeShipping ? 1 : 0,
//                   sale ? 1 : 0,
//                   null,
//                   null,
//                   value
//                 )
//               );
//               setRating(value);
//             }}
//             value={rating}
//           >
//             <Stack direction='column'>
//               <Radio value='4'>4★ & above</Radio>
//               <Radio value='3'>3★ & above</Radio>
//               <Radio value='2'>2★ & above</Radio>
//               <Radio value='1'>1★ & above</Radio>
//             </Stack>
//           </RadioGroup>
//         </AccordionPanel>
//       </AccordionItem>
//     </Accordion>
//   );
// };

// export default Filters;

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import styles from './filters.module.scss';
import { useQuery } from '../../hooks/useQuery';
import { useDispatch, useSelector } from 'react-redux';
import { getProductsHandler, setPage } from '../../redux/homeSlice';
import { setFilters } from '../../redux/productSlice';
import { useSearchParams } from 'react-router-dom';

const Filters = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const toast = useToast();
  let [searchParams, setSearchParams] = useSearchParams();

  const is_free_shipping = query.get('is_free_shipping');
  const min_discount = query.get('min_discount');
  const rating = query.get('rating');

  const { filters } = useSelector((state) => state.product);
  const [min, setMin] = useState(null);
  const [max, setMax] = useState(null);
  const [sale, setSale] = useState(false);

  useEffect(() => {
    dispatch(
      setFilters({
        isFreeShipping: is_free_shipping === '1',
        rating,
      })
    );
  }, [dispatch, is_free_shipping, rating]);

  // Function to update filters and dispatch
  const updateFilters = (updatedFields) => {
    dispatch(setPage(1));
    dispatch(setFilters(updatedFields));
  };

  const handlePriceValidationAndUpdate = () => {
    const minPrice = Number(min);
    const maxPrice = Number(max);

    if (!min) {
      toast({
        title: 'Error',
        description: 'Please enter min price',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    if (!max) {
      toast({
        title: 'Error',
        description: 'Please enter max price',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    if (minPrice > maxPrice) {
      toast({
        title: 'Error',
        description: 'Min price should be less than max price',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    updateFilters({ min: minPrice, max: maxPrice });
  };
  const queryParams = {};
  for (const entry of searchParams.entries()) {
    // make object key value pair

    queryParams[entry[0]] = entry[1];
  }

  useEffect(() => {
    if (min_discount) setSale(true);
  }, [min_discount]);

  console.log(sale, 'sale');

  return (
    <Accordion
      className={styles.accordion}
      defaultIndex={[0, 1, 2, 3]}
      allowMultiple
    >
      <AccordionItem className={styles.item}>
        <h2>
          <AccordionButton className={styles.button}>
            <Box as='span' flex='1' textAlign='left'>
              Delivery options & services
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} className={styles.panel}>
          <Checkbox
            isChecked={filters.isFreeShipping}
            onChange={(e) => {
              setSearchParams({
                ...queryParams,
                is_free_shipping: e.target.checked ? 1 : 0,
              });
            }}
          >
            Free Shipping
          </Checkbox>
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem className={styles.item}>
        <h2>
          <AccordionButton className={styles.button}>
            <Box as='span' flex='1' textAlign='left'>
              Discounts
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} className={styles.panel}>
          <Checkbox
            isChecked={sale}
            onChange={(e) => {
              setSale(e.target.checked);
              dispatch(
                setFilters({
                  sale: e.target.checked,
                })
              );
              if (!e.target.checked) {
                delete queryParams.min_discount;
                delete queryParams.max_discount;
                setSearchParams({
                  ...queryParams,
                });
              }
            }}
          >
            Sale
          </Checkbox>
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem className={styles.item}>
        <h2>
          <AccordionButton className={styles.button}>
            <Box as='span' flex='1' textAlign='left'>
              Price
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} className={styles.panel}>
          <Flex align={'center'} gap={'5px'}>
            <Input
              onChange={(e) => {
                setMin(e.target.value);
              }}
              value={min}
              width={'45%'}
              type='text'
              placeholder='Min'
            />
            <Box flex={1}>-</Box>
            <Input
              onChange={(e) => {
                setMax(e.target.value);
              }}
              value={max}
              width={'45%'}
              type='text'
              placeholder='Max'
            />
            <Button
              onClick={handlePriceValidationAndUpdate}
              width={'30px'}
              variant={'primary'}
            >
              OK
            </Button>
          </Flex>
          <Flex justify={'flex-end'}>
            <Text
              textTransform={'uppercase'}
              color={'#F41106'}
              cursor={'pointer'}
              fontSize={'14px'}
              onClick={() => {
                setMin('');
                setMax('');
                updateFilters({ min: null, max: null });
              }}
            >
              Reset
            </Text>
          </Flex>
        </AccordionPanel>
      </AccordionItem>
      <AccordionItem className={styles.item}>
        <h2>
          <AccordionButton className={styles.button}>
            <Box as='span' flex='1' textAlign='left'>
              Rating
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} className={styles.panel}>
          <Checkbox
            isChecked={filters.rating}
            onChange={(e) => {
              if (e.target.checked) {
                updateFilters({ rating: 5 });
                setSearchParams({
                  ...queryParams,
                  rating: 5,
                });
              } else {
                delete queryParams.rating;
                setSearchParams({
                  ...queryParams,
                });

                updateFilters({ rating: null });
              }
            }}
          >
            High Rating
          </Checkbox>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default Filters;
