import {
  Box,
  Flex,
  Image,
  ListItem,
  Text,
  UnorderedList,
  Link,
} from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import WhatsappIcon from '../icons/whatsapp';
import CallIcon from '../icons/call';
import styles from './footer.module.scss';

const Footer = () => {
  return (
    <Box className={styles.footer} bg='#EEE' p={'76px 120px 44px'}>
      <Flex gap={'118px'} className={styles.flex} justifyContent='space-around'>
        <Box>
          <Text
            className={styles.title}
            fontSize={'20px'}
            fontWeight={700}
            lineHeight={'20px'}
          >
            Contact Us
          </Text>
          <Flex mt='20px' flexDir={'column'} gap={'20px'}>
            <Flex gap={'11px'} align={'center'}>
              <WhatsappIcon />
              <Box>
                <Text fontSize={'16px'} fontWeight={700}>
                  Whats App
                </Text>
                <Text mt='2px' fontSize={'16px'} fontWeight={600}>
                  +1 202-918-2132
                </Text>
              </Box>
            </Flex>
            <Flex gap={'11px'} align={'center'}>
              <CallIcon />
              <Box>
                <Text fontSize={'16px'} fontWeight={700}>
                  Call Us
                </Text>
                <Text mt='2px' fontSize={'16px'} fontWeight={600}>
                  +1 202-918-2132
                </Text>
              </Box>
            </Flex>
          </Flex>
          <Box mt='100px' className={styles.downloads}>
            <Text fontSize={'20px'} fontWeight={700}>
              Download App
            </Text>
            <Flex gap={'13px'} mt='14px'>
              <Image
                height={'58px'}
                src={require('../../assets/img/app/appstore.png')}
                className={styles.app}
              />
              <Image
                height={'58px'}
                src={require('../../assets/img/app/playstore.png')}
                className={styles.app}
              />
            </Flex>
          </Box>
        </Box>
        <Box>
          <Text
            fontSize={'20px'}
            borderBottom={'3px solid #303030'}
            fontWeight={700}
            lineHeight={'20px'}
            paddingBottom={'16px'}
            className={styles.title}
          >
            Help & Support
          </Text>
          <UnorderedList mt='20px'>
            {/* <Link as={RouterLink} to="/terms-and-conditions">
             <ListItem mb="10px">Shipping Info</ListItem>
           </Link> */}
            {/* <Link as={RouterLink} to="/return-policy">
             <ListItem mb="10px">Return Policy</ListItem>
           </Link> */}
            <Link as={RouterLink} to="/how-to-order">
              <ListItem mb="10px">How to Order</ListItem>
            </Link>
            <Link as={RouterLink} to="/how-to-track-order">
              <ListItem mb="10px">How to Track Order</ListItem>
            </Link>
            <Link as={RouterLink} to="/size-guide">
              <ListItem mb="10px">Size Guide</ListItem>
            </Link>
            {/* <Link as={RouterLink} to="/vip">
             <ListItem mb="10px">VIP</ListItem>
           </Link> */}
            <Link as={RouterLink} to="/refund-policy">
              <ListItem mb="10px">Refund Policy</ListItem>
            </Link>
          </UnorderedList>
        </Box>
        <Box>
          <Text
            fontSize={'20px'}
            borderBottom={'3px solid #303030'}
            fontWeight={700}
            lineHeight={'20px'}
            paddingBottom={'16px'}
            className={styles.title}
          >
            Customer Services
          </Text>
          <UnorderedList mt='20px'>
            <Link as={RouterLink} to="/about-us">
              <ListItem mb='10px'>About Us</ListItem>
            </Link>
            <Link as={RouterLink} to="/terms-and-conditions">
              <ListItem mb="10px">Terms & Conditions</ListItem>
            </Link>
            <Link as={RouterLink} to="/qna">
              <ListItem mb="10px">Q&A</ListItem>
            </Link>
            <Link as={RouterLink} to="/privacy-policy">
              <ListItem mb='10px'>Privacy Policy</ListItem>
            </Link>
            <Link as={RouterLink} to="/cookie-notice">
              <ListItem mb='10px'>Cookie Notice</ListItem>
            </Link>
            <Link as={RouterLink} to="/shipping-return-cancellation-policy">
              <ListItem mb='10px'>Shipping, Return & Cancellation Policy</ListItem>
            </Link>
          </UnorderedList>
        </Box>
      </Flex>

      <Flex
        justify={'center'}
        borderTop={'1px solid #ED8B85'}
        paddingTop={'30px'}
        mt='86px'
      >
        <Text fontSize={'20px'}>© 2022 All rights reserved.</Text>
      </Flex>
    </Box>
  );
};

export default Footer;
