import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Box, Divider, Image, Text, useMediaQuery } from '@chakra-ui/react';
import { Navigation, Pagination } from 'swiper/modules';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const HotDealBanner = ({ items, slidesPerView, spaceBetween }) => {
  const navigate = useNavigate();
  const {
    homeData: { hot_deals },
  } = useSelector((state) => state.home);
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');

  return (
    <Box className='deal-banner' mb='40px'>
      <Text
        fontSize={'24px'}
        fontWeight={700}
        lineHeight={'30px'}
        color={'#666'}
        mb='30px'
      >
        Top <span style={{ color: '#F41106' }}>Hot Deals</span>
      </Text>
      <Divider mb='35px' mt='-10px' />
      <Swiper
        loop={true}
        pagination={true}
        allowSlidePrev={true}
        allowSlideNext={true}
        modules={[Pagination, Navigation]}
        navigation
        slidesPerView={isSmallerThan767 ? 1 : slidesPerView}
        spaceBetween={spaceBetween}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {hot_deals.map((item, index) => (
          <SwiperSlide key={index}>
            <Image
              borderRadius={'16px'}
              h={'207'}
              w={'100%'}
              objectFit={'cover'}
              src={item.image}
              cursor={'pointer'}
              onClick={() => {
                navigate(
                  `product/listing?page_no=1&min_discount=${item.min_discount}&max_discount=${item.max_discount}&is_free_shipping=1&is_sold=${item.is_sold}&rating=${item.rating}`
                );
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default HotDealBanner;
