import {
  Container,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  RiBankLine,
  RiCheckboxCircleLine,
  RiCheckboxLine,
  RiTruckLine,
  RiUser2Line,
  RiUserLine,
} from 'react-icons/ri';
import ShippingForm from '../checkout/shipping-address';
import PersonalDetail from '../checkout/personal-detail';
import Payment from '../checkout/payment';
import PaymentStatus from '../checkout/payment-status';
import OrderDetails from './order-details';

const CreateOrder = () => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <>
      <Flex py='20px' justify={'space-between'} align={'center'}>
        <Text fontSize={'18px'} fontWeight={600}>
          Checkout
        </Text>
      </Flex>
      <Tabs mt='30px' index={tabIndex} onChange={(index) => setTabIndex(index)}>
        <TabList justifyContent={'space-around'} alignSelf='center'>
          <Tab>
            <RiTruckLine /> <Text ml='10px'>Shipping</Text>
          </Tab>
          {/* <Tab>
            <RiUserLine /> <Text ml='10px'>Personal Details</Text>
          </Tab> */}
          <Tab>
            {' '}
            <RiBankLine /> <Text ml='10px'>Payment</Text>
          </Tab>
          <Tab>
            <RiCheckboxCircleLine /> <Text ml='10px'>Confirmation</Text>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Flex gap={'1.25rem'}>
              <ShippingForm setTabIndex={setTabIndex} />
              <OrderDetails />
            </Flex>
          </TabPanel>
          {/* <TabPanel>
            <Flex gap={'1.25rem'}>
              <PersonalDetail setTabIndex={setTabIndex} />
              <OrderDetails />
            </Flex>
          </TabPanel> */}
          <TabPanel>
            <Flex gap={'1.25rem'}>
              <Payment setTabIndex={setTabIndex} />
              <OrderDetails />
            </Flex>
          </TabPanel>
          <TabPanel>
            <PaymentStatus />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default CreateOrder;
