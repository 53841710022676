import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  useToast,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormInput from '../../components/form-input';

const AddressModal = ({ isOpen, onClose }) => {
  const toast = useToast();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW='70%' width="70%">
        <ModalHeader borderBottom='#EDEDED 1px solid'>Add Address</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              fullName: '',
              email: '',
              phone: '',
              address: '',
              pinCode: '',
              city: '',
              state: '',
              country: '',
            }}
            validationSchema={Yup.object({
              fullName: Yup.string().required('Required'),
              email: Yup.string().email('Invalid email address').required('Required'),
              phone: Yup.string().required('Required'),
              address: Yup.string().required('Required'),
              pinCode: Yup.string().required('Required'),
              city: Yup.string().required('Required'),
              state: Yup.string().required('Required'),
              country: Yup.string().required('Required'),
            })}
            onSubmit={(values) => {
              console.log(values);
              // Handle the form submission
              toast({
                title: 'Address saved successfully.',
                status: 'success',
                duration: 9000,
                isClosable: true,
              });
              onClose();
            }}
          >
            {({ isValid }) => (
              <Flex flexDir="column" as={Form} gap="1.25em" mt="1.26rem">
                <Flex flexDir="column" gap="20px">
                  <Flex gap="20px" w='100%'>
                    <FormInput
                      name="fullName"
                      type="text"
                      label="Full Name"
                      placeholder="Full Name"
                      flexWidth="50%"
                    />
                    <FormInput
                      name="email"
                      type="email"
                      label="Email"
                      placeholder="Email"
                      flexWidth="50%"
                    />
                  </Flex>
                  <Flex gap="20px">
                    <FormInput
                      name="phone"
                      type="number"
                      label="Phone Number"
                      placeholder="Phone Number"
                      flexWidth="50%"
                    />
                    <FormInput
                      name="address"
                      type="text"
                      label="Address"
                      placeholder="Address"
                      flexWidth="50%"
                    />
                  </Flex>
                </Flex>
                <Flex flexDir="row" gap="20px">
                    
                    <FormInput
                      name="pinCode"
                      type="number"
                      label="Pin Code"
                      placeholder="Pin Code"
                      flexWidth="25%"
                    />
                    <FormInput
                      name="city"
                      type="text"
                      label="City"
                      placeholder="City"
                      flexWidth="25%"
                    />
                    <FormInput
                      name="state"
                      type="text"
                      label="State"
                      placeholder="State"
                      flexWidth="25%"
                    />
                    <FormInput
                      name="country"
                      type="text"
                      label="Country"
                      placeholder="Country"
                      flexWidth="25%"
                    />
                </Flex>
                <hr/>
                <Flex justify="flex-end" mt={4}>
                  <Button onClick={onClose} mr={3} variant='secondary'>Close</Button>
                  <Button type="submit" variant='primary' isDisabled={!isValid}>
                    Save Address
                  </Button>
                </Flex>
              </Flex>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddressModal;
