import { Flex, Text, RadioGroup, Radio, Stack } from '@chakra-ui/react';
import { useField } from 'formik';

const FormRadioInput = ({ name, options, label, flexDir }) => {
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField(name);

  return (
    <Flex flexDir={'column'} gap={'10px'}>
      <Text
        as={'label'}
        textStyle={'body-rg'}
        fontWeight={400}
        lineHeight={'18px'}
      >
        {label}
      </Text>

      <RadioGroup
        value={field.value}
        onChange={helpers.setValue}
        colorScheme={'green'}
      >
        <Stack direction={flexDir} gap={'1.25rem'} w={'100%'}>
          {options.map((option) => (
            <Radio key={option.value} value={option.value}>
              {option.label}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
    </Flex>
  );
};

export default FormRadioInput;
