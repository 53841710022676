import React, { lazy, useEffect, useRef, useState, Suspense } from 'react';
import { RiArrowRightDoubleFill } from 'react-icons/ri';
import './productDetail.scss';
import SimilarProduct from './similar-product';
import Slider from './slider';
import ProductDescription from './product-description';
import RightComponent from './right-component';
import {
  Box,
  Button,
  Flex,
  Select,
  Skeleton,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  fetchProductDetails,
  fetchReportCategores,
  reportProductHandler,
} from '../../redux/productSlice';
import NumberGroupInput from '../../components/number-group-input';
import SimilarProducts from './similar-product';
import Rating from './rating';
import ReviewCard from './reviews';
import { setOnLoginOpen } from '../../redux/homeSlice';
import Modal from '../../components/modal';
import { useQuery } from '../../hooks/useQuery';
import isEmpty from 'is-empty';
import { useEffectOnce } from '../../hooks/useEffectOnce';

const Logistics = lazy(() => import('./logistics'));

const ProductDetail = ({ fromHome, sku_id }) => {
  const toast = useToast();
  const {
    isOpen: isOpenReportProduct,
    onOpen: onOpenReportProduct,
    onClose: onCloseReportProduct,
  } = useDisclosure();
  const dispatch = useDispatch();
  const { id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const query = useQuery();
  const [isExpanded, setIsExpanded] = useState(false);
  const [showViewMore, setShowViewMore] = useState(false);
  const contentRef = useRef(null);
  const { productDetails, productId, isProductLoading, reportCategores } =
    useSelector((state) => state.product);
  const { userData } = useSelector((state) => state.user);
  const { guestInfo } = useSelector((state) => state.guest);
  const [reason, setReason] = useState('');

  useEffect(() => {
    if ((id || productId) && (userData?.id || guestInfo?.id))
      dispatch(
        fetchProductDetails(
          id || productId,
          searchParams.get('sku_id') || sku_id,
          userData?.id || guestInfo?.id
        )
      );
  }, [dispatch, id, productId, sku_id, guestInfo, userData]);

  useEffectOnce(() => {
    dispatch(fetchReportCategores());
  });

  const product = productDetails?.products;

  useEffect(() => {
    const checkContentHeight = () => {
      if (contentRef.current) {
        const contentHeight = contentRef.current.scrollHeight;
        setShowViewMore(contentHeight > 1200);
      }
    };

    // delay added
    const timeoutId = setTimeout(checkContentHeight, 100);

    return () => clearTimeout(timeoutId);
  }, [product?.description, productDetails]);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div className='container'>
      <div className='product-container'>
        <Flex
          gap={'1.25rem'}
          p={'20px'}
          w={'100%'}
          flexDir={{ base: 'column', md: 'row' }}
        >
          <Box flex={1} w={'70%'}>
            <Flex gap={'20px'}>
              <Flex
                gap={'1.87rem'}
                width={{ base: '100%', md: '360px' }}
                flexDir={'column'}
              >
                <div className='swiper'>
                  {isProductLoading ? (
                    <Skeleton height={'360px'} width={'360px'} />
                  ) : (
                    <Slider
                      images={productDetails?.images}
                      thumbImages={productDetails?.thumb_images}
                    />
                  )}
                </div>
              </Flex>

              <Box
                className='product-des'
                flex={1}
                w={{ base: '100%', md: '45%' }}
              >
                <ProductDescription fromHome={fromHome} />
              </Box>
            </Flex>
            {!fromHome && (
              <>
                {' '}
                <SimilarProducts />
                <Box
                  className='features-and-ratings'
                  style={{ padding: '20px' }}
                >
                  <Rating fromHome />
                </Box>
                <ReviewCard />
                {product?.description && (
                  <Box mt={4} className='product-description-box'>
                    <Flex justify={'space-between'} align={'center'} mb='20px'>
                      <Text fontSize='lg' fontWeight='bold'>
                        Description:
                      </Text>
                      <Text
                        fontSize={'18px'}
                        fontWeight={600}
                        textDecorationLine={'underline'}
                        cursor={'pointer'}
                        onClick={() => {
                          const token = localStorage.getItem('token');
                          if (!token) {
                            return dispatch(setOnLoginOpen(true));
                          }
                          onOpenReportProduct();
                        }}
                      >
                        Report product
                      </Text>
                    </Flex>

                    <Box
                      maxH={isExpanded ? 'none' : '1200px'}
                      overflow='hidden'
                      position='relative'
                      ref={contentRef}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: product?.description,
                        }}
                      />
                    </Box>
                    {showViewMore && (
                      <Box display='flex' justifyContent='center' mt={5}>
                        <Text
                          cursor='pointer'
                          fontWeight={600}
                          textAlign='center'
                          textDecoration='underline'
                          mt={4}
                          onClick={handleToggle}
                          colorScheme='blue'
                          variant='outline'
                        >
                          {isExpanded ? 'Show Less' : 'Show More'}
                        </Text>
                      </Box>
                    )}
                  </Box>
                )}
              </>
            )}
          </Box>

          <Box
            className='right-component'
            w={{ base: '100%', md: '23%' }}
            minW={'326px'}
            pos={'sticky'}
            top={'100px'}
            maxH={`calc(100vh - 100px)`}
            overflowY={'auto'}
          >
            <Suspense fallback={'Loading...'}>
              <Logistics fromHome={fromHome} />
            </Suspense>

            <RightComponent />
          </Box>

          {/* </Flex> */}
        </Flex>
      </div>

      <Modal
        isOpen={isOpenReportProduct}
        onClose={onCloseReportProduct}
        title='Report Product'
      >
        <Text>Please tell us about the issue</Text>
        <Select
          onChange={(e) => {
            if (e.target.value === '-1') {
              setReason('');
              return;
            }
            setReason(e.target.value);
          }}
        >
          <option value='-1'>Select</option>
          {reportCategores?.map((category) => (
            <option key={category.id} value={category?.name}>
              {category?.name}
            </option>
          ))}
        </Select>
        <Flex justify={'flex-end'} align={'center'} gap={'20px'} mt='20px'>
          <Button variant='secondary' onClick={onCloseReportProduct}>
            Cancel
          </Button>
          <Button
            variant='primary'
            onClick={() => {
              const payload = {
                user_id: userData.id,
                product_id: product.id,
                category: reason,
                other_data: '',
              };
              dispatch(reportProductHandler(payload)).then((res) => {
                if (res.status === 200) {
                  toast({
                    title: 'Product reported successfully',
                    status: 'success',
                  });
                  onCloseReportProduct();
                } else {
                  toast({
                    title: 'Product report failed',
                    status: 'error',
                  });
                }
              });
            }}
          >
            Submit
          </Button>
        </Flex>
      </Modal>
    </div>
  );
};

export default ProductDetail;
