import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Skeleton,
  Text,
  useOutsideClick,
} from '@chakra-ui/react';
import React, { useEffect, useRef } from 'react';

import styles from './categories.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCategory } from '../../../redux/productSlice';
import { Link } from 'react-router-dom';
import { setHomeData, setSubCategoryOpen } from '../../../redux/homeSlice';
import { ArrowDown } from '../../icons/arrow-down';
import { ArrowDownWhite } from '../../icons/arrow-down-white';

const Categories = () => {
  const dispatch = useDispatch();
  const { homeData, isLoading, subCategoryOpen } = useSelector(
    (state) => state.home
  );
  const { selectedCategory } = useSelector((state) => state.product);
  const ref = useRef();

  useOutsideClick({
    ref: ref,
    handler: () => {
      dispatch(setSelectedCategory({}));
    },
  });

  useEffect(() => {
    return () => {
      dispatch(setSelectedCategory({}));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!subCategoryOpen) {
      dispatch(setSelectedCategory({}));
    }
    return () => {};
  }, [dispatch, subCategoryOpen]);

  return (
    <Box>
      <Flex
        bg='#FFF'
        padding='20px 0px'
        justify='center'
        align='center'
        mt='1px'
        className={styles.categories}
        zIndex={2}
        pos='relative'
        marginBottom='20px'
      >
        <Flex columnGap='20px' rowGap={'15px'} flexWrap={'wrap'} width='100%' justifyContent='space-between'>
          {isLoading
            ? [0, 1, 2, 3, 4].map((item) => (
                <Skeleton
                  padding='9px 18px'
                  height='38px'
                  borderRadius='33px'
                  width='20%'
                  minW='160px'
                  key={item}
                />
              ))
            : homeData?.header_categories?.map((item, index) => (
                <Button
                  key={index}
                  maxW={'fit-content'}
                  padding='9px 18px'
                  fontSize='14px'
                  _hover={{
                    bg: '#F41106',
                    color: '#fff',
                  }}
                  onMouseEnter={() => {
                    const _temp = [...homeData.header_categories];
                    const map = _temp.map((item, itemIndex) => {
                      if (index === itemIndex) {
                        return { ...item, isActive: true };
                      }
                      return { ...item, isActive: false };
                    });
                    dispatch(
                      setHomeData({
                        ...homeData,
                        header_categories: map,
                      })
                    );
                  }}
                  onMouseLeave={() => {
                    const _temp = [...homeData.header_categories];
                    const map = _temp.map((item, itemIndex) => {
                      if (index === itemIndex) {
                        return { ...item, isActive: false };
                      } else {
                        return { ...item };
                      }
                    });
                    dispatch(
                      setHomeData({
                        ...homeData,
                        header_categories: map,
                      })
                    );
                  }}
                  color={selectedCategory?.id === item.id ? '#fff' : '#000'}
                  fontWeight={600}
                  borderRadius='33px'
                  rightIcon={
                    item?.subcat_data?.length > 0 && item.isActive ? (
                      <ArrowDownWhite />
                    ) : (
                      <ArrowDown />
                    )
                  }
                  onClick={() => {
                    dispatch(setSubCategoryOpen(!subCategoryOpen));
                    dispatch(setSelectedCategory(item));
                  }}
                  bg={selectedCategory?.id === item.id ? '#F41106' : '#F0F0F0'}
                  flex='1 0 auto'
                  width='auto'
                >
                  {item.prime_name}
                </Button>
              ))}
        </Flex>
      </Flex>
      {subCategoryOpen && (
        <Box pos='relative' padding='20px 10px'>
          <Box className={styles.child_cat_list} ref={ref}>
            <SimpleGrid columns={4} spacing='20px' mt='1rem'>
              {selectedCategory?.subcat_data?.map((item, index) => (
                <Box key={index}>
                  <Text
                    fontWeight={600}
                    fontSize='1rem'
                    textTransform='uppercase'
                    className={styles.child_cat_title}
                  >
                    {item.name}
                  </Text>
                  <Flex flexDir='column' gap='15px' mt='1.25rem'>
                    {item.subcat_data.map((subItem, subIndex) => (
                      <Link
                        key={subIndex}
                        to={`/product/listing?category_id=${subItem.id}`}
                      >
                        <Text
                          fontSize='1rem'
                          textTransform='capitalize'
                          cursor='pointer'
                          color='#000'
                          _hover={{ color: '#F41106' }}
                        >
                          {subItem.name}
                        </Text>
                      </Link>
                    ))}
                  </Flex>
                </Box>
              ))}
            </SimpleGrid>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Categories;
