import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProductsHandler } from '../../redux/homeSlice';
import { useQuery } from '../../hooks/useQuery';
import { FaSortDown, FaSortUp } from 'react-icons/fa';
import { setFilters } from '../../redux/productSlice';

const SortBy = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const categoryId = query.get('category_id');
  const page_no = query.get('page_no');
  const min_discount = query.get('min_discount');
  const max_discount = query.get('max_discount');
  const is_sold = query.get('is_sold');
  const { filters } = useSelector((state) => state.product);

  const [isBestMatch, setIsBestMatch] = useState(true);
  const [isOrder, setIsOrder] = useState(false);
  const [isPrice, setIsPrice] = useState(false);
  const [priceClicked, setPriceClicked] = useState(false);

  return (
    <Flex align={'center'} gap={'10px'} justify={'flex-end'} mb='10px'>
      <Text>Sort By</Text>
      <Flex>
        <Flex
          bg={isBestMatch ? '#f41106' : 'white'}
          color={isBestMatch ? '#fff' : '#000'}
          border={'1px solid #ddd'}
          p={'5px 7px'}
          borderTopLeftRadius={'20px'}
          borderBottomLeftRadius={'20px'}
          width={'130px'}
          align={'center'}
          justify={'center'}
          cursor={'pointer'}
          fontSize={'.87rem'}
          fontWeight={600}
          onClick={() => {
            setIsBestMatch(true);
            setIsOrder(false);
            setIsPrice(false);
            setPriceClicked(false);
            dispatch(
              setFilters({ ...filters, best_match: 1, order: 0, isPrice: 0 })
            );
          }}
        >
          Best Match
        </Flex>
        <Flex
          bg={isOrder ? '#f41106' : 'white'}
          color={isOrder ? '#fff' : '#000'}
          align={'center'}
          justify={'center'}
          width={'130px'}
          border={'1px solid #ddd'}
          p={'5px 7px'}
          cursor={'pointer'}
          fontSize={'.87rem'}
          fontWeight={600}
          onClick={() => {
            setIsBestMatch(false);
            setIsOrder(true);
            setIsPrice(false);
            setPriceClicked(false);
            dispatch(
              setFilters({ ...filters, best_match: 0, order: 1, isPrice: 0 })
            );
          }}
        >
          Orders
        </Flex>
        <Flex
          bg={priceClicked ? '#f41106' : 'white'}
          color={priceClicked ? '#fff' : '#000'}
          align={'center'}
          justify={'center'}
          width={'130px'}
          border={'1px solid #ddd'}
          p={'5px 7px'}
          borderTopRightRadius={'20px'}
          borderBottomRightRadius={'20px'}
          cursor={'pointer'}
          fontSize={'.87rem'}
          fontWeight={600}
          onClick={() => {
            setIsBestMatch(false);
            setIsOrder(false);
            setIsPrice(!isPrice);
            setPriceClicked(true);
            dispatch(
              setFilters({
                ...filters,
                best_match: 0,
                order: 0,
                isPrice: isPrice ? 0 : 1,
              })
            );
          }}
        >
          <Flex gap={'10px'} align={'center'} justify={'space-between'}>
            Price
            <Flex flexDir={'column'}>
              <Box mb={'-17px'}>
                <FaSortUp
                  color={priceClicked && !isPrice ? '#fff' : ' #000'}
                  size={'16px'}
                />
              </Box>
              <Box>
                <FaSortDown
                  color={priceClicked && isPrice ? '#fff' : ' #000'}
                  size={'16px'}
                />
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SortBy;
