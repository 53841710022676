import { Box, Container, Flex, Text, useOutsideClick } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import SubCategories from '../sub-categories';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAllCategories,
  setSubCategories,
  setSubSubCategories,
} from '../../redux/homeSlice';
import styles from './header.module.scss';

const Categories = ({ onCloseCategory }) => {
  const dispatch = useDispatch();
  const { allCategories } = useSelector((state) => state.home);
  const [catTitile, setCatTitle] = useState('');
  const ref = useRef();
  useOutsideClick({
    ref: ref,
    handler: () => {
      onCloseCategory(false);
    },
  });

  useEffect(() => {
    if (allCategories.length > 0) {
      const firstCategory = allCategories[0];
      setCatTitle(firstCategory.name);
      dispatch(setSubCategories(firstCategory.subcat_data));
  
      const updatedCategories = allCategories.map((item, index) => ({
        ...item,
        isActive: index === 0, 
      }));
  
      dispatch(setAllCategories(updatedCategories));
      dispatch(setSubSubCategories([])); 
    }
  }, []); 

  return (
    <Box className={styles.all_category} ref={ref}>
      <Container maxW='100%' padding={'0 120px'}>
        <Flex>
          <Box width={'25%'} borderRight={'1px solid #E2E2E2'} pr='24px'>
            <Text
              fontSize={'16px'}
              fontWeight={600}
              lineHeight={'26px'}
              mb='12px'
              ml='10px'
            >
              CATEGORIES
            </Text>
            <Flex
              gap={'5px'}
              maxHeight={'465px'}
              overflowY={'auto'}
              direction='column'
            >
              {allCategories.map((item, index) => (
                <Text
                  cursor={'pointer'}
                  onMouseEnter={() => {
                    setCatTitle(item.name);
                    dispatch(setSubCategories(item.subcat_data));
                    const temp = allCategories.map((innerItem, innerIndex) => ({
                      ...innerItem,
                      isActive: innerIndex === index,
                    }));

                    dispatch(setAllCategories(temp));
                    dispatch(setSubSubCategories([]));
                  }}
                  onMouseLeave={() => {
                    const temp = allCategories.map((innerItem, innerIndex) => ({
                      ...innerItem,
                      isActive: innerIndex === index,
                    }));

                    dispatch(setAllCategories(temp));
                  }}
                  pl='10px'
                  py={'5px'}
                  backgroundColor={item.isActive ? '#FFEFEE' : 'transparent'}
                >
                  {item.name}
                </Text>
              ))}
            </Flex>
          </Box>
          <Box flex={1}>
            <SubCategories
              title={catTitile}
              setCatTitle={setCatTitle}
              onCloseCategory={onCloseCategory}
            />
          </Box>
        </Flex>
      </Container>
      <Box
        onClick={() => onCloseCategory(false)}
        className={styles.overlay}
      ></Box>
    </Box>
  );
};

export default Categories;
