import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  SimpleGrid,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { Formik } from "formik";
import * as Yup from "yup";
import FormInput from "../../components/form-input";
import { getQAData } from "../../services/staticpage-service";

const FAQS = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    name: "",
    email: "",
    question: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email address").required("Required"),
    question: Yup.string().required("Required"),
  });

  const handleSubmit = (values) => {
    console.log(values);
  };

  const getPageData = async () => {
    setLoading(true);
    try {
      const res = await getQAData();
      console.log(res.data.data, "log");
      setData(res.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPageData();
  }, []);

  return (
    <Box m="20px" bg="white" pb="20px" borderRadius="8px">
      <Box width="100%" mx="auto">
        <Flex flexDir="column" gap="5px" alignItems="center" justifyContent="center">
          <Text fontSize="24px" fontWeight="600" mt="20px">
            Q&A
          </Text>
          {/* <Text fontSize="16px" color="#696969" textAlign="center">
            We have shared some of the most frequently asked questions to help you out!
          </Text> */} 
        </Flex>
        <Box display="flex" justifyContent="center" m="20px">
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} width="100%">
            {loading ? (
              <Text >Loading...</Text>
            ) : (
              data.map((category) => (
                <Box key={category.id} bg="white" borderRadius="md" boxShadow="md" border="1px solid gray">
                  <Flex flexDir="row" mt="15px" ml="15px" alignItems="center">
                    <Box width="3px" height="17px" bgGradient="linear(to-b, #B5B8F0, #A5C7F1)" mr={2} mb={2} />
                    <Text fontSize="md" fontWeight="bold" mb={2}>
                      {category.name}
                    </Text>
                  </Flex>
                  <Divider mb="20px" mt="5px" />
                  <Box borderRight="1px solid #E2E8F0" borderLeft="1px solid #E2E8F0" borderRadius="10px" m="20px">
                    <Accordion allowToggle borderRadius="10px" fontSize="14px">
                      {category.qa.length > 0 ? (
                        category.qa.map((qaItem, index) => (
                          <AccordionItem
                            key={qaItem.id}
                            borderBottomRadius={index === category.qa.length - 1 ? "10px" : "0"}
                            borderTopRadius={index === 0 ? "10px" : "0"}
                            _notLast={{ borderBottom: "0" }}
                          >
                            <AccordionButton
                              _hover={{ color: "#949EB7" }}
                              _expanded={{ bg: "gray.100", color: "red.500" }}
                              borderTopRadius={index === 0 ? "10px" : "0"}
                            >
                              <Box flex="1" textAlign="left" fontSize="14px">
                                {qaItem.question}
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>{qaItem.answer}</AccordionPanel>
                          </AccordionItem>
                        ))
                      ) : (
                        <Text m="20px" color="gray.500">
                          No questions available.
                        </Text>
                      )}
                    </Accordion>
                  </Box>
                </Box>
              ))
            )}
          </SimpleGrid>
        </Box>

        <Box bg="white" borderRadius="md" boxShadow="md" m="20px" pt="5px" pb="15px" border="1px solid gray">
          <Flex flexDir="row" mt="15px" ml="15px" alignItems="center">
            <Box width="3px" height="17px" bgGradient="linear(to-b, #B5B8F0, #A5C7F1)" mr={2} mb={2} />
            <Text fontSize="md" fontWeight="bold" mb={2}>
              Still Have Questions?
            </Text>
          </Flex>
          <Text color="#696969" fontSize="14px" m="25px" mt="-10px">
            You can post your questions here, our support team is always active.
          </Text>
          <Divider mb="20px" mt="5px" />

          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {() => (
              <Flex spacing={4} width="100%" flexDir="column">
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} width="100%" pl="30px" pr="30px" pb="15px">
                  <FormInput label="Name" name="name" placeholder="Enter Your Name" width="100%" />
                  <FormInput label="Enter Your Email" name="email" placeholder="Enter Your Email" width="100%" />
                </SimpleGrid>
                <Box pl="30px" pr="30px">
                  <FormInput label="Question" name="question" placeholder="Enter Your Question" as="textarea" width="100%" />
                </Box>
                <Divider mt="4" mb="4" borderColor="gray" />
                <Flex justifyContent="flex-end" width="100%" pr="15px">
                  <Button type="submit" colorScheme="teal" width="60px">
                    Send
                  </Button>
                </Flex>
              </Flex>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};

export default FAQS;
