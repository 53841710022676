import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Skeleton,
  Spinner,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProductCard from '../product-card';
import {
  getProductsHandler,
  setAllProducts,
  setPage,
  setProductCard,
  setProducts,
} from '../../redux/homeSlice';
import { useQuery } from '../../hooks/useQuery';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation } from 'react-router-dom';

const ProductListing = ({ fromHome, onCartOpen }) => {
  const { products, productList, allProducts, hasMore, totalResults, page } =
    useSelector((state) => state.home);
  const { userData } = useSelector((state) => state.user);
  const { guestInfo } = useSelector((state) => state.guest);
  const { filters, isLoading } = useSelector((state) => state.product);
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');
  const [isSmallerThan1185] = useMediaQuery('(max-width: 1185px)');
  const [isSmallerThan1430] = useMediaQuery('(max-width: 1430px)');
  const [isSmallerThan1530] = useMediaQuery('(max-width: 1430px)');
  const [isSmallerThan1800] = useMediaQuery('(max-width: 1800px)');
  const [isSmallerThan1920] = useMediaQuery('(max-width: 1920px)');
  const dispatch = useDispatch();
  const query = useQuery();
  const categoryId = query.get('category_id');
  const page_no = query.get('page_no');
  const min_discount = query.get('min_discount');
  const max_discount = query.get('max_discount');
  const is_free_shipping = query.get('is_free_shipping');
  const is_sold = query.get('is_sold');
  const search_key = query.get('search_key');
  const location = useLocation();

  const [scrollCount, setScrollCount] = useState(0);
  const [showMoreButton, setShowMoreButton] = useState(false);

  const fetchData = useCallback(() => {
    dispatch(setAllProducts([]));

    const _page = 0;

    const params = {
      page_no: page,
      categoryId,
      min_discount,
      max_discount,
      is_free_shipping: is_free_shipping,
      is_sold,
      rating: filters?.rating,
      best_match: filters?.best_match,
      order: filters?.order || undefined,
      isPrice: filters?.isPrice || undefined,
      sale: filters?.sale ? 1 : undefined,
      min_price: filters?.min,
      max_price: filters?.max,
    };
    if (search_key) {
      params.search_key = search_key;
    }
    if (fromHome) {
      params.is_home = 1;
    }
    if (userData?.id || guestInfo?.id) dispatch(getProductsHandler(params));
    dispatch(setPage(_page + 1));
  }, [categoryId, userData?.id, guestInfo?.id, filters, search_key]);

  useEffect(() => {
    dispatch(setPage(1));
    fetchData();
    return () => dispatch(setPage(1));
  }, [fetchData, dispatch]);

  console.log(filters, 'filters');

  const fetchMoreData = () => {
    dispatch(setPage(page + 1));

    const params = {
      page_no: page,
      categoryId,
      min_discount,
      max_discount,
      is_free_shipping: is_free_shipping,
      is_sold,
      rating: filters?.rating,
      best_match: filters?.best_match,
      order: filters?.order || undefined,
      isPrice: filters?.isPrice || undefined,
      sale: filters?.sale ? 1 : undefined,
      min_price: filters?.min,
      max_price: filters?.max,
    };
    if (search_key) {
      params.search_key = search_key;
    }
    if (fromHome) {
      params.is_home = 1;
    }
    if (userData?.id || guestInfo?.id)
      dispatch(getProductsHandler(params, 'more'));
  };

  const handleShowMore = () => {
    setShowMoreButton(false);
    fetchMoreData();
  };

  const productsData = fromHome ? products : productList;

  const slidesPerView = location.pathname.includes('/product/listing')
    ? 4
    : isSmallerThan767
    ? 2
    : isSmallerThan1185
    ? 3
    : isSmallerThan1430
    ? 4
    : isSmallerThan1530
    ? 5
    : isSmallerThan1800
    ? 6
    : isSmallerThan1920
    ? 6
    : 6;

  let gridTemplate = productsData.length > 4 ? (!fromHome ? 'auto-fit' : 6) : 4;
  return (
    <>
      {totalResults === 0 && allProducts.length === 0 && (
        <Text fontSize={'18px'} p='5' textAlign={'center'}>
          No Product found!
        </Text>
      )}

      <InfiniteScroll
        dataLength={allProducts.length}
        next={fetchMoreData}
        hasMore={allProducts.length !== totalResults}
        // hasMore={allProducts.length !== totalResults && !showMoreButton}

        //you can create a spinner component which will be
        //displayed when the Items are being loaded
        loader={
          totalResults !== 0 && (
            <SimpleGrid
              columns={slidesPerView}
              gridTemplateColumns={`repeat(${slidesPerView}, minmax(210px, 1fr))`}
              spacing={'13px'}
              mb='20px'
            >
              {[0, 1, 2, 3, 4].map((item, index) => (
                <Skeleton
                  height={'271px'}
                  _hover={{
                    border: '1px solid var(--w, #F41106)',
                    boxShadow:
                      '0px 151px 42px 0px rgba(0, 0, 0, 0.00), 0px 97px 39px 0px rgba(0, 0, 0, 0.01), 0px 54px 33px 0px rgba(0, 0, 0, 0.03), 0px 24px 24px 0px rgba(0, 0, 0, 0.04), 0px 6px 13px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 0px rgba(0, 0, 0, 0.05)',
                  }}
                  border={'1px solid #EDEDED'}
                  borderRadius={'16px'}
                  bg={'#fff'}
                  className='product-card'
                  mb='20px'
                ></Skeleton>
              ))}
            </SimpleGrid>
          )
        }
      >
        <SimpleGrid
          columns={slidesPerView}
          gridTemplateColumns={`repeat(${slidesPerView}, minmax(210px, 1fr))`}
          spacing={'13px'}
          mb='20px'
        >
          {' '}
          {allProducts?.map((item, index) => (
            <ProductCard
              onCartOpen={onCartOpen}
              item={item}
              onMouseEnter={() => {
                //show icons on hover
                const _temp = [...products];
                const map = products.map((item, itemIndex) => {
                  if (index === itemIndex) {
                    return { ...item, showIcons: true };
                  }
                  return { ...item, showIcons: false };
                });
                dispatch(setProductCard(map));
              }}
              onMouseLeave={() => {
                const _temp = [...products];
                const map = _temp.map((item, itemIndex) => {
                  if (index === itemIndex) {
                    return { ...item, showIcons: false };
                  }
                  return { ...item, showIcons: false };
                });
                dispatch(setProductCard(map));
              }}
              fromListing
            />
          ))}
        </SimpleGrid>
      </InfiniteScroll>

      {/* {showMoreButton && (
        <Button
          cursor='pointer'
          fontSize={18}
          fontWeight={600}
          textAlign='center'
          textDecoration='underline'
          variant='secondary'
          onClick={handleShowMore}
          colorScheme='teal'
          m='20px'
          mx='auto'
          display='block'
          border='none'
        >
          Show More
        </Button>
      )} */}
    </>
  );
};

export default ProductListing;
